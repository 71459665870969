import component from './Switch.vue'

export default component

export const slideRight = {
  enterActiveClass: 'duration-300',
  enterFromClass: 'opacity-0 rotate-180',
  enterToClass: 'opacity-100 rotate-[360deg]',
  leaveActiveClass: 'duration-300',
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0',
}
export const slideLeft = {
  enterActiveClass: 'duration-300',
  enterFromClass: 'opacity-0 rotate-180',
  enterToClass: 'opacity-100 rotate-0',
  leaveActiveClass: 'duration-300',
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0',
}
