<script setup>
import * as VKID from '@vkid/sdk'
import { setErrors } from '@formkit/vue'
import { useMutation } from '@tanstack/vue-query'
import { v4 } from 'uuid'
import { onMounted, ref } from 'vue'
import { toast } from 'vue-sonner'

import { useAuthStore } from '@/stores/auth.js'

import { IconEye, IconEyeOff } from '@tabler/icons-vue'

import Button from '@/components/glass/button'
import UIHeading from '@/components/glass/heading'
import useHttp from '@/core/http.js'
import createSocialAuthWindow from '@/utils/social.js'

const props = defineProps({
  isMobile: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['close', 'navigate'])
const form = ref({})
const auth = useAuthStore()

const { isPending, mutate } = useMutation({
  mutationFn: async (body) =>
    await useHttp('/auth/login', {
      method: 'POST',
      body,
    }),
  onError: (error) => {
    setErrors('user-auth', error.data.errors?.global ?? [], error.data.errors)
  },
  onSuccess: ({ token }) => {
    if (!props.isMobile) {
      auth.loginWithToken(token)
      emit('close')
      return
    }
    sendEventToWebView('login', token)
  },
})

function sendEventToWebView(event, value) {
  const message = {
    action: event,
    data: value,
  }

  messageHandler.postMessage(JSON.stringify(message))
  message.type = 'opener'
  window.opener.postMessage(JSON.stringify(message), '*')
}

const mutationSocial = useMutation({
  mutationFn: async (body) =>
    await useHttp(`/auth/socials/${body.social}`, {
      method: 'POST',
      body,
    }),
  onError: (error) => {
    // TODO: Catch
    console.log(error, 'error')
  },
  onSuccess: ({ token }) => {
    if (!props.isMobile) {
      auth.loginWithToken(token)
      emit('close')
      return
    }
    sendEventToWebView('login', token)
  },
})

function createWindow(social) {
  createSocialAuthWindow(social, async (event) => {
    if (event) {
      if (event.data.event === 'auth') {
        mutationSocial.mutate(event.data.query)
      }
    }
  })
}

function openEsiaModal() {
  createWindow('esia')
}

function loadVkAuth() {
  const verificationCode = v4().toString()

  VKID.Config.init({
    app: '51658169',
    redirectUrl: 'https://molodaya-arctica.ru/api/auth/social/vk',
    scope: 'email phone',
    responseMode: VKID.ConfigResponseMode.Callback,
    codeVerifier: verificationCode,
  })

  // Создание экземпляра кнопки.
  const oneTap = new VKID.OneTap()

  // Получение контейнера из разметки.
  const container = document.getElementById('vk-id')

  // Проверка наличия кнопки в разметке.
  if (container) {
    // Отрисовка кнопки в контейнере с именем приложения APP_NAME, светлой темой и на русском языке.
    oneTap
      .render({
        container: container,
        scheme: VKID.Scheme.DARK,
        lang: VKID.Languages.RUS,
      })
      .on(VKID.WidgetEvents.ERROR, (err) => console.error(err)) // handleError — какой-либо обработчик ошибки.

    oneTap.on(VKID.OneTapInternalEvents.LOGIN_SUCCESS, function (payload) {
      useHttp('/auth/socials/vk', {
        method: 'POST',
        body: {
          ...payload,
          verification_code: verificationCode,
        },
      })
        .then(async ({ token }) => {
          await auth.loginWithToken(token)
          emit('close')
        })
        .catch(() => {
          toast.error('Попробуйте ещё раз', {
            description:
              'Если вы продолжаете получать ошибки — свяжитесь с администрацией',
          })
        })
    })
  }
}

onMounted(() => {
  loadVkAuth()
})

const passwordInputType = ref('password')

function switchEye({ node }) {
  passwordInputType.value = node.props.type === 'text' ? 'password' : 'text'
}
</script>

<template>
  <div>
    <div class="mb-8 flex items-center justify-between">
      <UIHeading styles="2" tag="1" title="Вход" />
      <button
        :disabled="mutationSocial.isPending.value"
        @click="$emit('navigate', 'register')"
      >
        Регистрация
      </button>
    </div>
    <FormKit
      id="user-auth"
      v-model="form"
      :actions="false"
      :disabled="mutationSocial.isPending.value"
      type="form"
      @submit.prevent="mutate(form)"
    >
      <FormKit
        label="E-mail"
        name="email"
        placeholder="mail@example.com"
        type="email"
        @keyup.enter.prevent.stop="mutate(form)"
      />
      <FormKit
        label="Пароль"
        placeholder="•••••••••"
        name="password"
        required
        :type="passwordInputType"
        @keyup.enter.prevent.stop="mutate(form)"
      >
        <template #suffix="context">
          <button
            type="button"
            class="px-3 text-black-400 transition hover:text-black-900"
            @click.stop.prevent.self="switchEye(context)"
          >
            <component
              class="size-5"
              :is="context.type === 'password' ? IconEye : IconEyeOff"
            />
          </button>
        </template>
      </FormKit>
      <div class="mb-9 flex items-center justify-between">
        <FormKit
          :classes="{ outer: '$remove:mb-4', wrapper: '$remove:mb-3' }"
          label="Запомнить меня"
          name="remember"
          type="checkbox"
        />
        <button
          :disabled="mutationSocial.isPending.value"
          tabindex="-1"
          type="button"
          @click.prevent="$emit('navigate', 'reset')"
        >
          Восстановить пароль
        </button>
      </div>
    </FormKit>
    <div class="flex flex-col gap-y-5">
      <Button
        :disabled="mutationSocial.isPending.value"
        :loading="isPending"
        class="w-full"
        size="xl"
        text="Войти"
        @click="mutate(form)"
      />
      <div class="flex flex-col gap-y-4">
        <div class="flex items-center gap-4 text-center">
          <span class="h-px w-full bg-black-100 dark:bg-black-500" />
          <span class="flex-1 whitespace-nowrap">Войти другим способом</span>
          <span class="h-px w-full bg-black-100 dark:bg-black-500" />
        </div>
        <!--        <button-->
        <!--          class="relative flex w-full items-center justify-center gap-2 overflow-hidden rounded-md bg-white px-7 py-3.5 text-blue-600 ring-1 ring-inset ring-blue-600"-->
        <!--          :disabled="mutationSocial.isPending.value"-->
        <!--          @click="createWindow('esia')"-->
        <!--        >-->
        <!--          <div-->
        <!--            v-if="mutationSocial.isPending.value"-->
        <!--            class="absolute flex size-full items-center justify-center bg-white"-->
        <!--          >-->
        <!--            <IconLoader2 class="size-6 animate-spin" />-->
        <!--          </div>-->
        <!--          <img-->
        <!--            class="flex-none"-->
        <!--            src="/images/socials/gosuslugi.svg"-->
        <!--            alt="Госуслуги"-->
        <!--            draggable="false"-->
        <!--          />-->
        <!--          <span>Войти через Госуслуги</span>-->
        <!--        </button>-->

        <div id="vk-id" />
      </div>
    </div>
  </div>
</template>
