<template>
  <div
    class="flex space-x-2"
    :class="{ 'pointer-events-none': context.readonly || context.disabled }"
  >
    <button
      v-for="index in maxStars"
      :key="index"
      type="button"
      class="text-black-300 focus:outline-none"
      :class="{
        '!text-amber-500':
          value >= index || hoverRating >= index || value >= index,
        'text-black-300': value < index,
      }"
      @click="updateRating(index)"
      @mouseover="hoverRating = index"
      @mouseleave="hoverRating = 0"
    >
      <component
        :is="hoverRating >= index || value >= index ? IconStarFilled : IconStar"
        class="size-6"
      />
    </button>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import { IconStar, IconStarFilled } from '@tabler/icons-vue'

const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
  modelValue: {
    type: Number,
    default: 0,
  },
  maxStars: {
    type: Number,
    default: 5,
  },
})

const maxStars = ref(props.maxStars)
const hoverRating = ref(0)

const value = computed(() => props.context.value)

const updateRating = (index) => {
  if (props.context.readonly || props.context.disabled) return
  props.context.node.input(index)
}
</script>
