<script setup>
import {
  useCycleList,
  useElementHover,
  useElementVisibility,
} from '@vueuse/core'
import { onBeforeUnmount, ref, watch } from 'vue'

const { state: position, next } = useCycleList([
  [0, 0],
  [-10, -10],
  [0, -10],
  [10, -10],
  [10, 10],
  [0, 10],
  [-10, 10],
  [0, 10],
  [0, 0],
])

const center = [
  [14, 0],
  [-14, 0],
]

let interval

function startInterval() {
  interval = setInterval(() => next(), 1400)
}

const target = ref(null)
const isTargetVisible = useElementVisibility(target)
const isHovered = useElementHover(target)

watch(
  isTargetVisible,
  (value) => (value ? startInterval() : clearInterval(interval)),
  {
    immediate: true,
  },
)

onBeforeUnmount(() => {
  clearInterval(interval)
})
</script>

<template>
  <svg
    ref="target"
    viewBox="0 0 221 265"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8312 14.3376C-37.573 103.309 42.7945 264.026 94.4551 264.026C169.079 258.283 251.343 80.3488 209.247 22.9488C183.991 -11.4891 131.762 37.2979 108.805 65.9961C81.2526 -12.0679 37.0525 -8.63785 19.8312 14.3376Z"
      fill="white"
    />
    <path
      class="transition-transform duration-300"
      :style="{
        transform: !isHovered
          ? `translate(${position[0]}px, ${position[1]}px)`
          : `translate(${center[0][0]}px, ${center[0][1]}px)`,
      }"
      d="M92.9651 98.218C92.9651 107.728 83.9706 115.438 72.8752 115.438C61.7798 115.438 52.7852 107.728 52.7852 98.218C52.7852 88.7077 61.7798 80.998 72.8752 80.998C83.9706 80.998 92.9651 88.7077 92.9651 98.218Z"
      fill="#0F183D"
    />
    <path
      class="transition-transform duration-300"
      :style="{
        transform: !isHovered
          ? `translate(${position[0]}px, ${position[1]}px)`
          : `translate(${center[1][0]}px, ${center[1][1]}px)`,
      }"
      d="M173.325 98.2173C173.325 107.728 164.33 115.437 153.235 115.437C142.14 115.437 133.145 107.728 133.145 98.2173C133.145 88.707 142.14 80.9973 153.235 80.9973C164.33 80.9973 173.325 88.707 173.325 98.2173Z"
      fill="#0F183D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.8312 14.3376C-37.573 103.309 42.7945 264.026 94.4551 264.026C169.079 258.283 251.343 80.3488 209.247 22.9488C183.991 -11.489 131.762 37.2979 108.805 65.9961C81.2526 -12.0679 37.0525 -8.63785 19.8312 14.3376ZM100.187 97.5666C100.187 108.662 87.3372 117.657 71.4866 117.657C55.636 117.657 42.7866 108.662 42.7866 97.5666C42.7866 86.4712 55.636 77.4766 71.4866 77.4766C87.3372 77.4766 100.187 86.4712 100.187 97.5666ZM151.846 117.657C167.697 117.657 180.546 108.662 180.546 97.5666C180.546 86.4712 167.697 77.4766 151.846 77.4766C135.996 77.4766 123.146 86.4712 123.146 97.5666C123.146 108.662 135.996 117.657 151.846 117.657Z"
      fill="#9A3AED"
    />
  </svg>
</template>
