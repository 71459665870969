import pkg from 'lz-string'

/**
 * lzDecompress
 *
 * @param {String} lzString
 * @return DecompressedValue
 */
export function lzDecompress(lzString) {
  const val = encodeURI(lzString).replaceAll('%20', '+')
  return JSON.parse(pkg.decompressFromBase64(val))
}
/**
 * lzCompress
 *
 * @param {any} value
 * @return {String} CompressedValue
 */
export function lzCompress(value) {
  return pkg.compressToBase64(JSON.stringify(value))
}
