<template>
  <div ref="boxRef" class="inline-block w-full">
    <!-- Reference -->
    <div ref="reference" @click.prevent="handleClick">
      <slot :value="context.value">
        <ComboInput
          :model-value="context.value"
          :label-by="labelBy"
          :max="0"
          v-bind="inputOptionss"
        />
      </slot>
    </div>
    <!-- Popup -->
    <Transition
      v-bind="contentTransition"
      @before-enter="beforeEnterPopup"
      @after-enter="focusSearch"
    >
      <div
        v-show="state.isOpen"
        ref="floating"
        :style="floatingStyles"
        class="z-50 divide-y divide-black-200/30 rounded-md border border-black-100 bg-card text-card-foreground shadow dark:divide-black-200/20 dark:border-black-500"
      >
        <div v-if="search" class="flex items-center px-3">
          <IconSearch class="mr-2 size-4 flex-none" />
          <input
            ref="searchRef"
            class="flex h-9 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
            v-model="searchModel"
            type="text"
            placeholder="Поиск"
          />
        </div>
        <div
          class="relative max-h-56 overflow-y-auto p-1"
          @mouseleave="selectedIndex = -1"
        >
          <div
            v-if="state.isLoading"
            class="absolute inset-0 z-10 flex rounded-md bg-card"
          >
            <svg
              class="m-auto h-5 w-5 animate-spin text-card-foreground"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
          <div
            ref="optionsRef"
            v-for="(item, i) in filteredOptions"
            :key="item?.id || i"
            @mouseover="setSelectedIndex(i)"
            @click="selectOption(item)"
          >
            <slot
              name="item"
              :item="item"
              :index="i"
              :selected="selectedIndex === i"
            >
              <div
                class="relative flex cursor-pointer select-none items-center justify-between truncate rounded-sm px-2 py-1.5 text-sm outline-none"
                :class="{
                  'bg-black-100 dark:bg-black-900': selectedIndex === i,
                }"
              >
                <span v-text="item.label" class="truncate" />
                <div>
                  <component
                    v-if="selectedModelKeys.includes(item.original[trackBy])"
                    class="ml-3 size-4"
                    :is="mark ?? IconCheck"
                  />
                </div>
              </div>
            </slot>
          </div>
          <template v-if="!filteredOptions.length">
            <slot name="empty">
              <div
                class="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none"
                v-text="emptyPlaceholder"
              />
            </slot>
          </template>
        </div>
        <slot name="footer">
          <div v-if="clearAll" class="p-1">
            <button
              class="relative flex w-full select-none items-center justify-center rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-secondary"
              v-text="clearPlaceholder"
              @click="clearModelValue"
            />
          </div>
        </slot>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/vue'
import { onClickOutside, useMagicKeys } from '@vueuse/core'
import { useFuse } from '@vueuse/integrations/useFuse'
import { computed, nextTick, ref, toRaw, watchEffect } from 'vue'

import { IconCheck, IconSearch } from '@tabler/icons-vue'

import ComboInput from './ComboInput.vue'

// Arrow handlers
useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (!state.value.isOpen) return

    if (e.key === 'ArrowUp' && e.type === 'keydown') {
      e.preventDefault()
      upHandler()
    }
    if (e.key === 'ArrowDown' && e.type === 'keydown') {
      e.preventDefault()
      downHandler()
    }
    if (e.key === 'Enter' && e.type === 'keydown') {
      e.preventDefault()
      if (selectedIndex.value >= 0 && filteredOptions.value.length) {
        selectOption(optionsModel.value[selectedIndex.value])
      }
    }
  },
})

// Opening transition
const contentTransition = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0',
  enterToClass: 'opacity-100',
  leaveActiveClass: 'transition ease-in duration-200',
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0',
}

// Props
const props = defineProps({
  context: Object,
  modelValue: {
    type: [Array],
    default: [],
  },
  labelBy: {
    type: String,
    default: 'value',
  },
  labelFallback: {
    type: Array,
    default: ['title', 'name'],
  },
  trackBy: {
    type: String,
    default: 'id',
  },
  valueBy: {
    type: String,
    default: 'id',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  search: {
    type: [Boolean, Function],
    default: false,
  },
  autoFocus: {
    type: Boolean,
    default: true,
  },
  options: {
    type: Array,
    default: [],
  },
  inputOptions: {
    type: Object,
    default: {
      label: 'Select',
    },
  },
  mark: {
    type: [Object, Function],
    default: null,
  },
  closeOnSelect: {
    type: Boolean,
    default: true,
  },
  clearAll: {
    type: Boolean,
    default: false,
  },
  emptyPlaceholder: {
    type: String,
    default: 'Ничего не найдено',
  },
  clearPlaceholder: {
    type: String,
    default: 'Очистить все',
  },
})

const model = computed({
  get() {
    return props.context.value ? props.context.value : []
  },
  set() {},
})
const labelFallback = ref(
  props.context.attrs['label-fallback'] || props.labelFallback,
)
const search = ref(props.context.attrs.search || props.search)
const multiple = ref(props.context.attrs.multiple || props.multiple)
const trackBy = ref(props.context.attrs['track-by'] || props.trackBy)
const labelBy = ref(props.context.attrs['label-by'] || props.labelBy)
const valueBy = ref(props.context.attrs['value-by'] || props.valueBy)
const inputOptionss = ref(
  props.context.attrs['input-options'] || props.inputOptions,
)

// Emits
const emit = defineEmits(['update:model-value'])

// Component state
const state = ref({
  isOpen: false,
  isLoading: false,
  isError: false,
  isSearchExternal: typeof search.value !== 'boolean',
})

const optionsOrigins = ref(toRaw(props.context.options) ?? [])

// DOM Refs
const searchRef = ref(null)
const optionsRef = ref([])
const boxRef = ref(null)

// Extracts the values of the 'trackBy' key
// and flattens the result into a single array.
const selectedModelKeys = computed(() => {
  return valueBy.value
    ? model.value
    : model.value.flatMap((item) => item[trackBy.value])
})

const optionsModel = ref(
  prepareOptions([
    ...model.value,
    ...optionsOrigins.value.filter(
      (item) => !selectedModelKeys.value.includes(item[trackBy.value]),
    ),
  ]),
)

const searchModel = ref('')
const selectedIndex = ref(-1)

function setSelectedIndex(index) {
  selectedIndex.value = index
}

function tryGetFullItem(item) {
  const index = optionsOrigins.value.findIndex((i) => i[trackBy.value] === item)
  if (index >= 0) return optionsOrigins.value[index]
  return item
}

function prepareItem(item) {
  const prepared = tryGetFullItem(item)
  return {
    original: prepared,
    label: findLabelFallback(prepared),
  }
}

function prepareOptions(options) {
  return options.map((item) => prepareItem(item))
}

async function beforeEnterPopup() {
  if (!state.value.isSearchExternal) return
  optionsOrigins.value = await manualSearch(searchModel.value)
  optionsModel.value = prepareOptions([
    ...model.value,
    ...optionsOrigins.value.filter(
      (item) => !selectedModelKeys.value.includes(item[trackBy.value]),
    ),
  ])
}

function focusSearch() {
  if (search.value && props.context.autoFocus) {
    searchRef.value.focus()
  }
}

function scrollToElement(index) {
  if (optionsRef.value.length)
    optionsRef.value[index].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
}

function upHandler() {
  if (selectedIndex.value === -1) {
    selectedIndex.value = filteredOptions.value.length - 1
  } else {
    selectedIndex.value =
      (selectedIndex.value + filteredOptions.value.length - 1) %
      filteredOptions.value.length
  }

  scrollToElement(selectedIndex.value)
}

function downHandler() {
  selectedIndex.value = (selectedIndex.value + 1) % filteredOptions.value.length
  scrollToElement(selectedIndex.value)
}

function findLabelFallback(item) {
  if (labelBy.value && item[labelBy.value]) return item[labelBy.value]

  for (const key of labelFallback.value) {
    if (item.hasOwnProperty(key)) {
      return item[key]
    }
  }

  console.warn(
    '[Combobox]: None of the fallback keys exist in the item - ',
    item,
  )
  return item
}

function selectOption(item) {
  if (!item) return

  const option = JSON.parse(JSON.stringify(item.original))
  // console.log('option:', option)
  // console.log('model:', toRaw(model.value))
  let newValue

  const index = toRaw(model.value).findIndex(
    (item) => item === option[trackBy.value],
  )

  // console.log('index:', index, option[trackBy.value])

  const prepareValue = trackBy.value ? option[trackBy.value] : option

  if (multiple.value) {
    // console.log('??')
    if (index === -1) {
      newValue = [...model.value, prepareValue]
    } else {
      newValue = [...model.value]
      newValue.splice(index, 1)
    }
  } else {
    if (index === -1) {
      newValue = [prepareValue]
    } else {
      newValue = []
    }
  }

  if (!multiple.value && props.context.closeOnSelect) {
    state.value.isOpen = false
  }

  props.context.node.input(newValue)

  // Update options if selected by search
  if (state.value.isSearchExternal) {
    const foundIndex = optionsOrigins.value.findIndex(
      (item) => item[trackBy.value] === option[trackBy.value],
    )

    nextTick(() => {
      if (foundIndex === -1) {
        optionsOrigins.value.push(option)
        optionsModel.value = prepareOptions(optionsOrigins.value)
      }
    })
  }
}

function clearModelValue() {
  props.context.node.input([])
  emit('update:model-value', [])
}

// Fuse search
function fuseSearch(searchValue) {
  selectedIndex.value = -1
  // TODO: search keys by label fallback and labelBy optionsModel!!
  let keys = Object.keys(props.context.options[0])
    .filter((key) => {
      return typeof props.context.options[0][key] === 'string'
    })
    .map((item) => `original.${item}`)

  const { results } = useFuse(searchValue, optionsModel.value, {
    fuseOptions: {
      shouldSort: false,
      maxPatternLength: 32,
      useExtendedSearch: true,
      minMatchCharLength: 1,
      keys: keys.length > 0 ? keys : [],
    },
    matchAllWhenSearchEmpty: true,
  })

  return results.value.map((result) => result.item)
}

async function manualSearch(value) {
  if (typeof search.value === 'boolean') return
  selectedIndex.value = -1

  state.value.isError = false
  state.value.isLoading = true

  return await search
    .value(value)
    .catch(() => {
      state.value.isError = true
      return []
    })
    .finally(() => {
      state.value.isLoading = false
    })
}

const searchResult = ref([])
const filteredOptions = ref([])

watchEffect(async () => {
  if (typeof search.value !== 'boolean') {
    if (searchModel.value) {
      searchResult.value = prepareOptions(await manualSearch(searchModel.value))
      filteredOptions.value = searchResult.value
    } else {
      filteredOptions.value = optionsModel.value
    }
  } else {
    filteredOptions.value =
      search.value && searchModel.value
        ? fuseSearch(searchModel.value)
        : optionsModel.value
  }
})

// Floating
const reference = ref(null)
const floating = ref(null)
const placement = ref('bottom-start')

const { floatingStyles } = useFloating(reference, floating, {
  placement,
  middleware: [
    flip(),
    offset({ mainAxis: 6 }),
    size({
      apply({ availableWidth, availableHeight, elements }) {
        // Change styles, e.g.
        Object.assign(elements.floating.style, {
          maxWidth: `${availableWidth}px`,
          maxHeight: `${availableHeight}px`,
        })
      },
    }),
  ],
  whileElementsMounted: autoUpdate,
})

onClickOutside(boxRef, () => (state.value.isOpen = false))

function handleClick() {
  state.value.isOpen = !state.value.isOpen
  floating.value.style.width = `${reference.value.offsetWidth}px`
}
</script>
