import { watch } from 'vue'

export const useQueryEvents = (query, callbacks) => {
  const { onSuccess, onError, onRefetch } = callbacks

  watch(
    () => query.isRefetching.value,
    () => {
      if (!query.isRefetching.value) {
        onRefetch?.(query.data.value)
      }
    },
  )

  watch(
    () => query.isSuccess.value,
    () => {
      if (query.isSuccess.value) {
        onSuccess?.(query.data.value)
      }
    },
    { immediate: true },
  )

  watch(
    () => query.isError.value,
    () => {
      if (query.isError.value) {
        onError?.(query.error.value)
      }
    },
    { immediate: true },
  )
}
