<template>
  <div class="space-y-4">
    <LoadingView
      class="aspect-square items-center"
      :is-loading="mutationGetQr.isPending.value"
    >
      <img class="aspect-square" :src="src" alt="qr" />
    </LoadingView>
    <p
      v-if="ticket?.event?.title"
      class="text-xl"
      v-text="ticket.event.title"
    />
    <Button class="w-full" text="Закрыть" @click="$emit('close')" />
  </div>
</template>

<script setup>
import { useMutation } from '@tanstack/vue-query'
import { ref } from 'vue'
import { toast } from 'vue-sonner'

import Button from '@/components/glass/button'
import LoadingView from '@/components/glass/loading-view'
import useHttp from '@/core/http.js'

const props = defineProps({
  ticket: {
    type: Object,
    default: {},
  },
  getQrEndpoint: {
    type: String,
    default: '',
  },
  getTicketEndpoint: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['close'])

const src = ref(null)
const ticket = ref(props.ticket)

const mutationGetQr = useMutation({
  mutationFn: (id) =>
    useHttp(
      props.getQrEndpoint
        ? props.getQrEndpoint
        : `/auth/profile/tickets/${id}/qr`,
    ),
  onSuccess: (blob) => {
    src.value = URL.createObjectURL(blob)
  },
  onError: () => {
    if (props.getQrEndpoint) {
      toast.error('Не удалось получить QR код')
      return
    }
    toast.error('Не удалось получить билет')
  },
})

if (ticket.value.hasOwnProperty('id')) {
  mutationGetQr.mutate(ticket.value.id)
}

const mutationGetTicket = useMutation({
  mutationFn: () => useHttp(`${props.getTicketEndpoint}`, { method: 'POST' }),
  onSuccess: ({ resource }) => {
    ticket.value = resource
    mutationGetQr.mutate(resource.id)
  },
  onError: () => {
    toast.error('Не удалось получить билет')
  },
})

if (!Object.keys(ticket.value).length && props.getTicketEndpoint) {
  mutationGetTicket.mutate()
}

if (props.getQrEndpoint) {
  mutationGetQr.mutate()
}
</script>
