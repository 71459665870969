import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone.js'

import useSSRCookies from '@/utils/get-cookies.js'
import isBrowser from '@/utils/is-browser.js'

dayjs.extend(timezone)

export function userTimezone() {
  if (isBrowser) {
    useSSRCookies().set('_tz', dayjs.tz.guess())
  }
}

export function getUserTimezone() {
  return useSSRCookies().get('_tz')
}
