import { ref } from 'vue'
import { useModal, useModalSlot } from 'vue-final-modal'

import ModalContainer from '@/components/common/ModalContainer.vue'
import AppealsModal from '@/components/modals/AppealsModal.vue'
import FormModal from '@/components/modals/FormModal.vue'
import HtmlModal from '@/components/modals/HtmlModal.vue'
import QrModal from '@/components/modals/QrModal.vue'
import useHttp from '@/core/http.js'

export function openMaterialsModal(endpoint) {
  let isPending = ref(true)
  let content = ref('')

  useHttp(endpoint, { method: 'GET' })
    .then((res) => {
      content.value = res
    })
    .finally(() => (isPending = false))

  useModal({
    defaultModelValue: true,
    showSwipeBanner: true,
    component: ModalContainer,
    attrs: {
      containerClass: 'max-w-[720px] w-full m-auto p-5',
    },
    slots: {
      default: useModalSlot({
        component: HtmlModal,
        attrs: {
          content,
          isLoading: isPending,
          onClose() {
            close()
          },
        },
      }),
    },
  })
}

export function openQrModal({
  ticket = {},
  getQrEndpoint = '',
  getTicketEndpoint = '',
}) {
  const { close } = useModal({
    defaultModelValue: true,
    component: ModalContainer,
    attrs: {
      containerClass: 'max-w-[420px] w-full m-auto py-8 px-6',
      onClose() {
        close()
      },
    },
    slots: {
      default: useModalSlot({
        component: QrModal,
        attrs: {
          ticket,
          getQrEndpoint,
          getTicketEndpoint,
          onClose() {
            close()
          },
        },
      }),
    },
  })
}

export function openAppealsModal() {
  const { close } = useModal({
    defaultModelValue: true,
    component: ModalContainer,
    attrs: {
      containerClass: 'max-w-[620px] w-full m-auto p-10',
      onClose() {
        close()
      },
    },
    slots: {
      default: useModalSlot({
        component: AppealsModal,
        attrs: {
          onClose() {
            close()
          },
        },
      }),
    },
  })
}

export function createFormModal({
  formId,
  title,
  schema = [],
  errors = {},
  isLoading,
  onSubmit,
}) {
  const { open, close } = useModal({
    component: ModalContainer,
    attrs: {
      containerClass: 'max-w-[620px] w-full m-auto p-10',
      onClose() {
        close()
      },
    },
    slots: {
      default: useModalSlot({
        component: FormModal,
        attrs: {
          formId,
          title,
          schema,
          errors,
          isLoading,
          onSubmit(form) {
            onSubmit(form)
          },
          onClose() {
            close()
          },
        },
      }),
    },
  })
  return { open, close }
}
