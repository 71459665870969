<script setup>
import Loading from '@/components/common/Loading.vue'

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  placeholderClass: {
    type: String,
    default: '',
  },
  pulse: {
    type: Boolean,
    default: false,
  },
})

const contentTransition = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0',
  enterToClass: 'opacity-100',
  leaveActiveClass: 'transition ease-in duration-200',
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0',
}
</script>

<template>
  <Transition v-bind="contentTransition" mode="out-in">
    <div
      v-if="isLoading"
      class="relative flex overflow-hidden rounded-md p-6"
      :class="placeholderClass"
    >
      <div v-if="pulse" class="absolute inset-0 animate-pulse bg-muted" />
      <slot name="loading">
        <Loading class="mx-auto" />
      </slot>
    </div>
    <template v-else>
      <slot />
    </template>
  </Transition>
</template>
