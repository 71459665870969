<template>
  <component
    :is
    :disabled
    :class="[
      classes,
      sizes[size],
      variants[variant],
      {
        'opacity-60': loading,
        'pointer-events-none': disabled || loading,
        'flex-row-reverse': reverse,
      },
    ]"
  >
    <span v-if="text" :class="{ invisible: loading && !icon }" v-text="text" />
    <component
      v-if="icon"
      :is="loading ? IconLoader2 : icon"
      :class="[text ? position[size] : '', { 'animate-spin': loading }]"
    />
    <IconLoader2 v-if="!icon && loading" class="absolute animate-spin" />
  </component>
</template>

<script setup>
import { IconLoader2 } from '@tabler/icons-vue'

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  icon: {
    type: [Object, Function],
    default: null,
  },
  reverse: {
    type: Boolean,
    default: false,
  },
  is: {
    type: [Object, String],
    default: 'button',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
    validator: (value, props) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
  },
  variant: {
    type: String,
    default: 'primary',
    validator: (value, props) =>
      [
        'primary',
        'secondary',
        'neutral',
        'outline',
        'ghost',
        'link',
        'destructive',
      ].includes(value),
  },
})

const classes =
  'relative inline-flex justify-center h-min whitespace-nowrap ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 items-center rounded-md select-none'

const sizes = {
  xs: 'p-1.5 text-xs leading-4 [&>svg]:size-4' + (props.text ? ' px-2.5' : ''),
  sm:
    'p-2 text-sm font-medium leading-4 [&>svg]:size-5' +
    (props.text ? ' px-3' : ''),
  md:
    'p-2 text-base [&>svg]:size-6 [&>svg]:py-0.5' + (props.text ? ' px-4' : ''),
  lg:
    'p-3 text-base [&>svg]:size-6 [&>svg]:stroke-[1.8]' +
    (props.text ? ' px-6' : ''),
  xl:
    'p-3.5 text-lg leading-6 [&>svg]:size-6 [&>svg]:stroke-[1.7]' +
    (props.text ? ' px-7' : ''),
}

const position = {
  xs: props.reverse ? 'mr-1 -ml-0.5' : 'ml-1 -mr-0.5',
  sm: props.reverse ? 'mr-1.5' : 'ml-1.5',
  md: props.reverse ? 'mr-1.5 -ml-1' : 'ml-1.5 -mr-1',
  lg: props.reverse ? 'mr-1.5 -ml-1' : 'ml-1.5 -mr-1',
  xl: props.reverse ? 'mr-2.5 -ml-1.5' : 'ml-2.5 -mr-1.5',
}

const variants = {
  primary:
    'bg-primary text-primary-foreground hover:bg-primary/90 active:bg-primary/80',
  secondary:
    'bg-secondary text-secondary-foreground hover:bg-secondary/90 active:bg-secondary/80',
  neutral:
    'bg-neutral/40 text-neutral-foreground hover:bg-neutral/75 active:bg-neutral',
  outline:
    'outline dark:bg-black-950 bg-white outline-purple-600 dark:outline-purple-300 dark:active:bg-purple-300/20 dark:hover:bg-purple-300/10 hover:bg-purple-50 active:bg-purple-100',
  ghost:
    'hover:bg-secondary hover:text-secondary-foreground active:bg-secondary/80',
  link: 'hover:underline',
  destructive:
    'bg-destructive text-destructive-foreground hover:bg-destructive/90 active:bg-destructive/80',
}
</script>
