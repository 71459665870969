<template>
  <div ref="reference" class="relative w-full">
    <FormKit
      type="group"
      id="group"
      class="w-full"
      :value="modelValue"
      @input="
        (data) => {
          context.node.input(data)
          $emit('update:model-value', data)
        }
      "
    >
      <div
        class="flex w-full cursor-pointer flex-row-reverse items-center justify-between gap-x-2 bg-block hover:bg-black-100 hover:text-accent-foreground dark:bg-black-800 dark:hover:bg-black-900"
        :class="context.classes.input"
        @click="handleClick"
      >
        <div class="flex gap-2 opacity-70">
          <IconChevronDown class="size-5" />
        </div>
        <div class="flex select-none gap-2">
          <component
            v-if="context.attrs.icon"
            :is="context.attrs.icon"
            class="size-5 opacity-70"
          />
          <span class="text-sm" v-text="context.attrs.title" />
        </div>
      </div>

      <FloatingTransition
        v-bind="contentTransition"
        :reference
        :options="{ middleware: [offset(8)] }"
      >
        <div
          ref="floating"
          class="absolute z-50 rounded-md border border-black-100 bg-block px-4 py-5 dark:border-black-700 dark:bg-black-900"
          v-show="isOpen"
        >
          <FormKitSchema :schema="context.attrs.children" />
        </div>
      </FloatingTransition>
    </FormKit>
  </div>
</template>

<script setup>
import { offset } from '@floating-ui/vue'
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'

import { IconChevronDown } from '@tabler/icons-vue'

import FloatingTransition from '@/components/glass/floating-transition/index.js'

const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
  modelValue: {
    type: Object,
    default: {},
  },
})

const isOpen = ref(false)

const reference = ref(null)
const floating = ref(null)

onClickOutside(reference, () => {
  isOpen.value = false
})

const rendered = ref(false)

function handleClick() {
  rendered.value = false
  isOpen.value = !isOpen.value
}

const contentTransition = {
  enterActiveClass: 'transition ease-out duration-300',
  enterFromClass: 'opacity-0',
  enterToClass: 'opacity-100',
  leaveActiveClass: 'transition ease-in duration-200',
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0',
}
</script>
