<template>
  <button
    class="group relative h-6 w-11 rounded-full p-0.5 shadow-sm transition-colors"
    :class="{
      'bg-input': !modelValue,
      'bg-primary': modelValue,
    }"
    @click.prevent="handleClick"
  >
    <div
      class="absolute top-0.5 flex h-5 w-5 items-center justify-center rounded-full bg-background group-active:w-7"
      :class="{
        'left-0.5 translate-x-0': !modelValue,
        'left-0.5 translate-x-5 group-active:-left-1.5': modelValue,
      }"
      style="
        transition:
          width 120ms ease-in-out,
          left 120ms ease-in-out,
          transform 300ms ease;
      "
    >
      <transition v-bind="transition">
        <span v-if="inactiveIcon && !modelValue" class="relative">
          <component
            :is="inactiveIcon"
            class="absolute left-0 top-0 size-3 -translate-x-1/2 -translate-y-1/2 stroke-[4] text-input"
          />
        </span>
        <span v-else-if="activeIcon && modelValue" class="relative">
          <component
            :is="activeIcon"
            class="absolute left-0 top-0 size-3 -translate-x-1/2 -translate-y-1/2 stroke-[4] text-primary"
          />
        </span>
      </transition>
    </div>
  </button>
</template>

<script setup>
import { computed } from 'vue'

import { slideLeft, slideRight } from './index.js'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  activeIcon: {
    type: [Object, String, Function],
    default: null,
  },
  inactiveIcon: {
    type: [Object, String, Function],
    default: null,
  },
})

const emits = defineEmits(['update:modelValue'])

function handleClick() {
  emits('update:modelValue', !props.modelValue)
}

const transition = computed(() => (props.modelValue ? slideRight : slideLeft))
</script>
