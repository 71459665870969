<template>
  <svg width="0" height="0" class="hidden">
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_1"
    >
      <path
        d="M40 96C45.3688 96 50.3576 94.3727 54.5 91.5845C58.6424 94.3727 63.6312 96 69 96C74.3688 96 79.3576 94.3727 83.5 91.5845C87.6424 94.3727 92.6311 96 98 96C112.359 96 124 84.3594 124 70C124 55.6406 112.359 44 98 44C92.6311 44 87.6424 45.6273 83.5 48.4155C79.3576 45.6273 74.3688 44 69 44C63.6312 44 58.6424 45.6273 54.5 48.4155C50.3576 45.6273 45.3688 44 40 44C25.6406 44 14 55.6406 14 70C14 84.3594 25.6406 96 40 96Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_2"
    >
      <path
        d="M55.1421 27.8579C62.9526 20.0474 75.6159 20.0474 83.4264 27.8579L111.711 56.1421C119.521 63.9526 119.521 76.6159 111.711 84.4264L83.4264 112.711C75.6159 120.521 62.9526 120.521 55.1421 112.711L26.8579 84.4264C19.0474 76.6159 19.0474 63.9526 26.8579 56.1421L55.1421 27.8579Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_3"
    >
      <path
        d="M120.258 67C120.276 67.5619 120.286 68.138 120.286 68.7288C120.286 96.2927 111.742 118.638 101.203 118.638C94.4042 118.638 88.4359 109.339 85.0559 95.3393C81.6758 109.339 75.7076 118.638 68.9089 118.638C62.5781 118.638 56.9673 110.575 53.4959 98.1614C50.0245 110.575 44.4137 118.638 38.0828 118.638C27.5437 118.638 19 96.2932 19 68.7293C19 65.5095 19.2724 62.7181 19.7703 60.299C23.0145 38.07 42.1567 21 65.2857 21H74.2857C99.6908 21 120.286 41.5949 120.286 67H120.258Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_4"
    >
      <path
        d="M69.3571 118.107C43.2025 118.107 22 110 22 99.9999C22 93.5489 30.8231 87.8857 44.107 84.6785C30.8231 81.4713 22 75.8082 22 69.3571C22 63.35 29.6506 58.026 41.4296 54.7321C29.6506 51.4382 22 46.1143 22 40.1071C22 30.1068 43.2025 22 69.3571 22C95.5117 22 116.714 30.1068 116.714 40.1071C116.714 46.1143 109.064 51.4382 97.2847 54.7321C109.064 58.026 116.714 63.35 116.714 69.3571C116.714 75.8082 107.891 81.4713 94.6073 84.6785C107.891 87.8857 116.714 93.5489 116.714 99.9999C116.714 110 95.5117 118.107 69.3571 118.107Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_5"
    >
      <path
        d="M120.258 72.6381C120.276 72.0763 120.286 71.5001 120.286 70.9093C120.286 43.3454 111.742 21.0004 101.203 21.0004C94.4042 21.0004 88.4359 30.299 85.0559 44.2988C81.6758 30.299 75.7076 21.0004 68.9089 21.0004C62.5781 21.0004 56.9673 29.0632 53.4959 41.4768C50.0245 29.063 44.4137 21 38.0828 21C27.5437 21 19 43.345 19 70.9089C19 74.1287 19.2724 76.92 19.7703 79.3391C23.0145 101.568 42.1567 118.638 65.2857 118.638H74.2857C99.6908 118.638 120.286 98.0432 120.286 72.6381H120.258Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_6"
    >
      <path
        d="M84.3749 42.4296C87.6688 30.6506 92.9927 23 98.9999 23C109 23 117.107 44.2025 117.107 70.3571C117.107 96.5118 109 117.714 98.9999 117.714C92.9927 117.714 87.6688 110.064 84.3749 98.2847C81.081 110.064 75.7571 117.714 69.7499 117.714C63.2989 117.714 57.6358 108.891 54.4285 95.6073C51.2213 108.891 45.5582 117.714 39.1071 117.714C29.1068 117.714 21 96.5117 21 70.3571C21 44.2025 29.1068 23 39.1071 23C45.5582 23 51.2213 31.8231 54.4285 45.107C57.6358 31.8231 63.2989 23 69.7499 23C75.7571 23 81.081 30.6506 84.3749 42.4296Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_7"
    >
      <path
        d="M81.4607 33.392C93.4249 28.5538 102.908 27.8556 106.382 32.4694C109.218 36.2355 107.545 42.8716 102.506 50.7091C113.996 50.6643 122.023 53.2494 123.6 58.3838C125.322 63.9955 118.957 71.4105 107.675 78.3514C114.824 84.4377 118.378 90.3465 116.739 94.7906C114.72 100.263 105.27 102.311 92.2188 101.044C93.8579 110.593 92.7761 117.621 88.5669 119.991C83.8841 122.627 76.2793 118.963 67.9784 111.004C63.1682 119.515 57.842 124.32 53.0583 123.478C46.9237 122.398 43.1224 112.283 42.3539 97.7477C42.1515 97.7509 41.9502 97.7533 41.7499 97.755C31.3386 101.44 23.2388 101.627 20.0869 97.4405C18.0453 94.7289 18.341 90.5295 20.5471 85.4651C20.8471 84.5961 21.2818 83.7045 21.8435 82.796C23.0694 80.4954 24.6363 78.0541 26.5099 75.5218C18.003 68.855 13.6054 62.2594 15.3946 57.4087C17.049 52.9236 23.6945 50.7388 33.2231 50.794C29.9787 38.2425 30.5295 28.704 35.5763 25.8628C39.9978 23.3737 47.0243 26.5012 54.7821 33.5576C60.0993 22.5937 66.3004 16.129 71.7875 17.095C76.4842 17.9219 79.8132 24.0443 81.4607 33.392Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_8"
    >
      <path
        d="M25.6863 96.9756C22.6857 93.975 21 89.9053 21 85.6619V54.7595C21 50.516 22.6857 46.4463 25.6863 43.4458L42.4458 26.6863C45.4463 23.6857 49.516 22 53.7595 22H84.6619C88.9054 22 92.9751 23.6857 95.9756 26.6863L112.314 43.0244C115.314 46.025 117 50.0946 117 54.3381V86.0833C117 90.3267 115.314 94.3964 112.314 97.397L96.397 113.314C93.3964 116.314 89.3267 118 85.0833 118H53.3381C49.0946 118 45.025 116.314 42.0244 113.314L25.6863 96.9756Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_9"
    >
      <path
        d="M76.8996 43.5463C73.9984 37.4846 65.3687 37.4846 62.4675 43.5463L57.4792 53.9686C55.7144 57.6559 51.4535 59.4196 47.5989 58.0582L35.061 53.63C27.8828 51.0948 21.5553 59.0719 25.6579 65.4846L26.8152 67.2935C29.7381 71.8623 27.3637 77.9538 22.1199 79.3393L18.9564 80.1752C11.0146 82.2736 11.0145 93.5459 18.9563 95.6444L22.1199 96.4802C22.3036 96.5288 22.4837 96.5831 22.6603 96.6429C36.1742 99.8815 52.3285 101.764 69.6835 101.764C87.0385 101.764 103.193 99.8815 116.707 96.6429C116.883 96.5831 117.063 96.5288 117.247 96.4803L120.411 95.6444C128.353 93.546 128.353 82.2736 120.411 80.1752L117.247 79.3394C112.003 77.9538 109.629 71.8623 112.552 67.2935L113.709 65.4846C117.812 59.0719 111.484 51.0948 104.306 53.63L91.7682 58.0582C87.9136 59.4196 83.6527 57.6559 81.8879 53.9686L76.8996 43.5463Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_10"
    >
      <path
        d="M69 13C66.2386 13 64 15.2386 64 18V52.8282L45.3032 23.4441C43.8207 21.1143 40.7303 20.4274 38.4005 21.9098C36.0708 23.3923 35.3838 26.4827 36.8663 28.8124L53.8254 55.4655L26.4667 39.6699C24.0752 38.2892 21.0173 39.1086 19.6365 41.5C18.2558 43.8915 19.0752 46.9495 21.4667 48.3302L50.3396 65H17C14.2386 65 12 67.2386 12 70C12 72.7614 14.2386 75 17 75H50.3398L21.4667 91.6699C19.0752 93.0506 18.2558 96.1086 19.6365 98.5C21.0173 100.892 24.0752 101.711 26.4667 100.33L52.2495 85.4445L35.3242 109.937C33.7543 112.209 34.3233 115.323 36.595 116.893C38.8668 118.463 41.9811 117.894 43.551 115.622L64 86.0304V122C64 124.761 66.2386 127 69 127C71.7614 127 74 124.761 74 122V87.172L92.6968 116.556C94.1793 118.886 97.2697 119.573 99.5994 118.09C101.929 116.608 102.616 113.518 101.134 111.188L84.1745 84.5346L111.533 100.33C113.925 101.711 116.983 100.892 118.363 98.5C119.744 96.1086 118.925 93.0506 116.533 91.6699L87.6602 75H121C123.761 75 126 72.7614 126 70C126 67.2386 123.761 65 121 65H87.6603L116.533 48.3302C118.925 46.9495 119.744 43.8915 118.363 41.5C116.983 39.1086 113.925 38.2892 111.533 39.6699L85.7505 54.5556L102.676 30.0634C104.246 27.7916 103.677 24.6773 101.405 23.1074C99.1332 21.5375 96.0189 22.1065 94.449 24.3783L74 53.9697V18C74 15.2386 71.7614 13 69 13Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_11"
    >
      <path
        d="M109 30.4726C126.378 47.851 114.345 88.0606 96.9662 105.439C79.5879 122.817 51.4121 122.817 34.0337 105.439C16.6554 88.0606 16.6554 59.8847 34.0337 42.5064C51.4121 25.1281 91.6217 13.0943 109 30.4726Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_12"
    >
      <path
        d="M34.5486 25.4847C12.6982 59.5195 43.2894 121 62.9535 121C91.3585 118.803 122.672 50.7365 106.648 28.7788C97.0346 15.605 77.1541 34.2679 68.4155 45.246C57.9281 15.3836 41.1037 16.6957 34.5486 25.4847Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_13"
    >
      <path
        d="M51.275 88.725C32.3913 85.9747 19 79.0757 19 71C19 62.9448 32.3233 56.0604 51.131 53.296C53.7783 33.8758 60.7795 20 69 20C77.2 20 84.1868 33.8067 86.8491 53.1509C106.193 55.8132 120 62.8 120 71C120 79.2205 106.124 86.2217 86.704 88.869C83.9396 107.677 77.0552 121 69 121C60.9243 121 54.0253 107.609 51.275 88.725Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_14"
    >
      <path
        d="M62.3048 116.73C53.0044 112.15 41.7789 96.9094 33.994 76.9527C23.6518 50.4403 23.2982 25.6447 33.2044 21.5704C42.8121 17.6188 58.542 34.6099 68.9165 59.825C80.6071 35.7084 97.0039 19.7902 106.347 23.889C116.094 28.1654 114.472 52.612 102.723 78.4918C93.2646 99.3276 80.1258 114.74 70.2767 117.725C69.8431 117.876 69.3977 117.986 68.9415 118.057C67.0715 118.416 65.3404 118.284 63.8009 117.608C63.2683 117.375 62.7697 117.081 62.3048 116.73Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_15"
    >
      <path
        d="M16.4138 77.0317C15.4885 75.4819 15 73.7106 15 71.9056V68.1751C15 66.3246 15.5135 64.5104 16.4833 62.9344L35.6668 31.7593C37.4868 28.8016 40.7108 27 44.1835 27H94.8416C98.3437 27 101.591 28.8321 103.401 31.8299L122.56 63.5491C123.502 65.109 124 66.8968 124 68.7192V72.0694C124 73.8706 123.514 75.6383 122.592 77.1858L103.578 109.116C101.775 112.145 98.5104 114 94.9859 114H44.161C40.6406 114 37.3795 112.149 35.5748 109.126L16.4138 77.0317Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_16"
    >
      <path
        d="M28.4774 96.38C31.9112 79.0195 31.9112 60.9295 28.4774 43.5689C27.4335 38.2911 28.0735 34.046 30.6744 31.445C33.2106 28.9088 37.31 28.2372 42.4058 29.1731C59.9257 32.3909 78.1482 32.6088 95.5182 28.6607C101.196 27.3703 105.763 27.9333 108.504 30.6744C111.18 33.3508 111.78 37.7681 110.606 43.2601C106.857 60.7935 106.857 79.1554 110.606 96.6889C111.78 102.181 111.18 106.598 108.504 109.275C105.763 112.016 101.196 112.579 95.5182 111.288C78.1482 107.34 59.9257 107.558 42.4058 110.776C37.31 111.712 33.2106 111.04 30.6744 108.504C28.0735 105.903 27.4335 101.658 28.4774 96.38Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_17"
    >
      <path
        d="M35.5456 84.5644C25.3813 81.0887 19 76.0747 19 70.5C19 64.9253 25.3813 59.9113 35.5456 56.4356C30.816 46.7908 29.8492 38.7331 33.7911 34.7912C37.733 30.8492 45.7907 31.8161 55.4356 36.5457C58.9113 26.3813 63.9253 20 69.5 20C75.0748 20 80.0887 26.3813 83.5644 36.5456C93.2093 31.816 101.267 30.8492 105.209 34.7911C109.151 38.733 108.184 46.7907 103.454 56.4356C113.619 59.9113 120 64.9252 120 70.5C120 76.0747 113.619 81.0887 103.454 84.5644C108.184 94.2093 109.151 102.267 105.209 106.209C101.267 110.151 93.2093 109.184 83.5644 104.454C80.0887 114.619 75.0747 121 69.5 121C63.9253 121 58.9113 114.619 55.4356 104.454C45.7907 109.184 37.733 110.151 33.7911 106.209C29.8492 102.267 30.816 94.2093 35.5456 84.5644Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_18"
    >
      <path
        d="M35 60.5C35 41.4462 50.4462 26 69.5 26C88.5538 26 104 41.4462 104 60.5V113C104 114.105 103.105 115 102 115H37C35.8954 115 35 114.105 35 113V60.5Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_19"
    >
      <path
        d="M105.016 19.7555C97.0297 11.7032 73.6939 21.8985 52.8937 42.5274C32.0934 63.1562 21.7054 86.4069 29.6913 94.4591C34.2905 99.0966 43.981 97.6816 55.3463 91.7069C48.8631 102.834 47.5953 113.648 53.1075 119.206C60.943 127.107 79.5111 121.396 94.5804 106.451C109.65 91.5058 115.514 72.9856 107.678 65.085C103.826 61.2006 97.3791 60.6065 90.0293 62.7825C105.195 44.7555 111.866 26.6631 105.016 19.7555Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_20"
    >
      <path
        d="M30 70C30 41.8335 47.4609 19 69 19C90.5391 19 108 41.8335 108 70C108 98.1665 90.5391 121 69 121C47.4609 121 30 98.1665 30 70Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_21"
    >
      <path
        d="M69.4999 44.6099C85.2905 32.604 100.131 27.625 105.916 33.4098C109.905 37.3989 108.776 45.6941 103.754 55.6376C114.336 59.1174 121 64.1846 121 69.8259C121 75.4673 114.336 80.5344 103.754 84.0142C108.776 93.9577 109.905 102.253 105.916 106.242C100.131 112.027 85.2905 107.048 69.4999 95.0419C53.7094 107.048 38.8688 112.027 33.084 106.242C29.095 102.253 30.2242 93.9576 35.2464 84.0142C24.6641 80.5344 18 75.4673 18 69.8259C18 64.1846 24.6641 59.1175 35.2464 55.6376C30.2242 45.6942 29.0949 37.399 33.084 33.4099C38.8688 27.6252 53.7094 32.6041 69.4999 44.6099Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_22"
    >
      <path
        d="M68.3985 20.5301C68.2882 20.6405 68.1816 20.7538 68.0787 20.8701L68.0686 20.8601L66.7574 22.1885L66.7488 22.1798L20.8601 68.6721L20.8701 68.6822C20.7538 68.7851 20.6405 68.8917 20.5301 69.002C14.1524 75.3797 20.2474 91.815 34.1437 105.711C48.04 119.608 64.4753 125.703 70.853 119.325C70.9634 119.215 71.07 119.101 71.1729 118.985L71.183 118.995L72.4941 117.667L72.5027 117.675L118.392 71.183L118.381 71.1729C118.498 71.07 118.611 70.9634 118.721 70.853C125.099 64.4753 119.004 48.04 105.108 34.1437C91.2115 20.2474 74.7762 14.1524 68.3985 20.5301Z"
        fill="currentColor"
      />
    </symbol>

    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_23"
    >
      <path
        d="M35.5456 84.5644C25.3813 81.0887 19 76.0747 19 70.5C19 64.9253 25.3813 59.9113 35.5456 56.4356C30.816 46.7908 29.8492 38.7331 33.7911 34.7912C37.733 30.8492 45.7907 31.8161 55.4356 36.5457C58.9113 26.3813 63.9253 20 69.5 20C75.0748 20 80.0887 26.3813 83.5644 36.5456C93.2093 31.816 101.267 30.8492 105.209 34.7911C109.151 38.733 108.184 46.7907 103.454 56.4356C113.619 59.9113 120 64.9252 120 70.5C120 76.0747 113.619 81.0887 103.454 84.5644C108.184 94.2093 109.151 102.267 105.209 106.209C101.267 110.151 93.2093 109.184 83.5644 104.454C80.0887 114.619 75.0747 121 69.5 121C63.9253 121 58.9113 114.619 55.4356 104.454C45.7907 109.184 37.733 110.151 33.7911 106.209C29.8492 102.267 30.816 94.2093 35.5456 84.5644Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_24"
    >
      <path
        d="M94.4815 44.5185C93.9657 30.8901 82.7546 20 69 20C55.2454 20 44.0343 30.8901 43.5185 44.5185C29.8901 45.0343 19 56.2454 19 70C19 83.7546 29.8901 94.9657 43.5185 95.4815C44.0343 109.11 55.2454 120 69 120C82.7546 120 93.9657 109.11 94.4815 95.4815C108.11 94.9657 119 83.7546 119 70C119 56.2454 108.11 45.0343 94.4815 44.5185Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_25"
    >
      <path
        d="M24 55.4992C24 52.0704 25.7567 48.8807 28.6546 47.0478L62.1562 25.8583C66.037 23.4037 70.9784 23.379 74.8836 25.7947L109.261 47.0601C112.207 48.8826 114 52.1002 114 55.5645V102.346C114 110.078 107.732 116.346 100 116.346H38C30.268 116.346 24 110.078 24 102.346V55.4992Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_26"
    >
      <path
        d="M32.4157 36.7226C32.7675 36.3453 33.1349 35.965 33.5182 35.5816C51.4028 17.6971 71.8437 9.14144 79.1742 16.472C83.9031 21.2009 82.0211 31.3855 75.2885 42.8201C86.7232 36.0875 96.9077 34.2055 101.637 38.9344C106.04 43.3378 104.711 52.4719 99.0714 62.9408C109.54 57.3009 118.675 55.9719 123.078 60.3754C130.409 67.706 121.853 88.1469 103.969 106.031C101.879 108.121 99.8789 109.742 97.963 110.966C81.2833 123.132 56.8932 120.893 40.8056 104.806L34.5456 98.5456C16.8749 80.8749 15.9127 53.1872 32.3965 36.7034L32.4157 36.7226Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_27"
    >
      <path
        d="M69.5 20C61.9116 20 55.5196 25.1226 53.5936 32.0985C47.299 28.5277 39.1569 29.4253 33.7911 34.7911C28.4253 40.1569 27.5278 48.299 31.0985 54.5936C24.1227 56.5196 19 62.9116 19 70.5C19 78.0884 24.1227 84.4804 31.0985 86.4064C27.5277 92.701 28.4253 100.843 33.7911 106.209C39.1569 111.575 47.299 112.472 53.5936 108.902C55.5196 115.877 61.9116 121 69.5 121C77.0884 121 83.4804 115.877 85.4064 108.902C91.701 112.472 99.8431 111.575 105.209 106.209C110.575 100.843 111.472 92.701 107.902 86.4064C114.877 84.4804 120 78.0884 120 70.5C120 62.9116 114.877 56.5196 107.902 54.5936C111.472 48.299 110.575 40.157 105.209 34.7912C99.8431 29.4253 91.701 28.5278 85.4064 32.0985C83.4804 25.1227 77.0884 20 69.5 20Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_28"
    >
      <path
        d="M69.5 40C100.704 40 126 53.4315 126 70C126 86.5685 100.704 100 69.5 100C38.2959 100 13 86.5685 13 70C13 53.4315 38.2959 40 69.5 40Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_29"
    >
      <path
        d="M24.1738 110.842C24.0747 112.002 24.993 113 26.1571 113H112.533C113.697 113 114.616 112.002 114.517 110.842C110.435 63.0815 91.7629 27 69.3452 27C46.9275 27 28.2552 63.0815 24.1738 110.842Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_30"
    >
      <path
        d="M108.152 56.9335C114.448 44.013 109.533 28.2949 96.7901 21.3262C83.6665 14.1494 67.2097 18.9702 60.0328 32.0939L57.7521 36.2645L53.3531 34.4633C39.5106 28.7956 23.6945 35.4224 18.0267 49.2649C12.5233 62.7058 18.6118 78.0077 31.6422 84.0728C25.3463 96.9932 30.2611 112.711 43.0041 119.68C56.1278 126.857 72.5846 122.036 79.7614 108.912L82.0421 104.742L86.4411 106.543C100.284 112.211 116.1 105.584 121.767 91.7414C127.271 78.3004 121.182 62.9986 108.152 56.9335Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_31"
    >
      <path
        d="M69.2063 42.4371C86.2836 29.5784 102.356 24.3475 108.711 30.702C115.065 37.0565 109.834 53.1291 96.9756 70.2063C109.834 87.2836 115.065 103.356 108.711 109.711C102.356 116.065 86.2836 110.834 69.2063 97.9756C52.1291 110.834 36.0565 116.065 29.702 109.711C23.3475 103.356 28.5784 87.2836 41.4371 70.2063C28.5785 53.1291 23.3475 37.0566 29.702 30.702C36.0566 24.3475 52.1291 29.5785 69.2063 42.4371Z"
        fill="currentColor"
      />
    </symbol>
    <symbol
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 140"
      id="shape_32"
    >
      <path
        d="M105.86 69.8242C115.132 59.8227 114.905 44.1948 105.179 34.4688C95.4535 24.7428 79.8256 24.5159 69.8241 33.7879C59.8227 24.5158 44.1948 24.7428 34.4688 34.4688C24.7428 44.1948 24.5158 59.8227 33.7879 69.8241C24.5159 79.8256 24.7428 95.4535 34.4688 105.179C44.1948 114.905 59.8227 115.132 69.8241 105.86C79.8256 115.132 95.4535 114.905 105.179 105.179C114.905 95.4535 115.132 79.8256 105.86 69.8242Z"
        fill="currentColor"
      />
    </symbol>
  </svg>
</template>
<script setup lang="ts"></script>
