<script setup>
import { ref } from 'vue'

import Button from '@/components/glass/button'
import Heading from '@/components/glass/heading'

defineProps({
  formId: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  schema: {
    type: Array,
    default: [],
  },
  errors: {
    type: Object,
    default: {},
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const form = ref({})
</script>

<template>
  <div class="flex flex-col gap-4">
    <Heading v-if="title" :title="title" tag="2" styles="3" />
    <FormKit :id="formId" v-model="form" type="form" :actions="false">
      <FormKitSchema :schema="schema" />
    </FormKit>
    <Button
      :loading="isLoading"
      size="lg"
      text="Отправить"
      @click="$emit('submit', form)"
    />
  </div>
</template>
