<template>
  <footer
    class="relative overflow-hidden bg-purple-50 py-12 dark:bg-dark sm:py-[70px]"
  >
    <div
      class="container mx-auto grid grid-cols-2 justify-between max-xl:gap-5 md:grid-cols-[auto,1fr,1fr] lg:grid-cols-[auto,auto,auto,auto]"
    >
      <div class="flex flex-col">
        <MALogo class="max-sm:max-w-24" />
        <Button
          v-text="'Напиши нам'"
          size="lg"
          class="mt-6 h-11 w-min min-w-[140px] sm:mt-5 sm:min-w-[180px]"
          @click="openAppealsModal()"
        />
        <p
          class="mt-6 text-xxs text-black-600 dark:text-black-400 sm:text-xs lg:mt-16"
          v-text="`© ${new Date().getFullYear()} Молодая Арктика`"
        />
        <div class="flex flex-1 max-sm:items-end">
          <component
            class="mt-6 block lg:hidden"
            :is="linkBlock('Контакты', contacts)"
          />
        </div>
      </div>
      <div class="max-lg:order-2 max-md:col-span-2">
        <component
          class="mx-auto md:max-w-xs"
          :is="linkBlock('Телеграм', telegram)"
        />
        <component
          class="mx-auto mt-4 sm:mt-8 md:max-w-xs"
          :is="linkBlock('ВКонтакте', vk)"
        />
      </div>
      <div class="max-lg:order-1 max-sm:mt-2">
        <component
          class="sm:max-w-xs"
          :is="linkBlock('Полезные ссылки', links)"
        />
        <component
          class="mt-4 sm:mt-8 sm:max-w-xs"
          :is="linkBlock('Информация и документы ', information)"
        />
      </div>
      <div class="hidden lg:block">
        <component :is="linkBlock('Контакты', contacts)" />
      </div>
    </div>

    <!-- Heart -->
    <div class="container relative">
      <UIPupil
        class="absolute -right-8 bottom-0 w-[220px] translate-y-[70%] max-lg:hidden"
      />
    </div>
  </footer>
</template>

<script setup>
import { h } from 'vue'

import MALogo from '@/components/common/MALogo.vue'
import Button from '@/components/glass/button/index.js'
import UIPupil from '@/components/ui/UIPupil.vue'
import { openAppealsModal } from '@/utils/modals.js'

const telegram = [
  {
    title: 'Тг-канал «Молодая Арктика»',
    to: 'https://t.me/molarctic51',
  },
  {
    title: 'Тг-канал Губернатора Андрея Чибиса',
    to: 'https://t.me/andrey_chibis',
  },
]

const vk = [
  {
    title: 'ВК Губернатора Андрея Чибиса',
    to: 'https://vk.com/chibis.andrey',
  },
  {
    title: 'Комитет молодежной политики Мурманской области',
    to: 'https://vk.com/murmol51',
  },
  {
    title:
      'Региональный центр патриотического воспитания и допризывной подготовки молодежи',
    to: 'https://vk.com/patriot.murmana',
  },
  {
    title:
      'Государственное автономное учреждение Мурманской области «Молодая Арктика»',
    to: 'https://vk.com/molarctic51',
  },
]

const links = [
  { title: 'Правительство Мурманской области', to: 'https://gov-murman.ru/' },
  {
    title: 'Комитет молодежной политики Мурманской области',
    to: 'https://youth.gov-murman.ru/',
  },
  { title: 'Портал «Наш Север»', to: 'https://nashsever51.ru/' },
]
const information = [
  {
    title: 'Пользовательское соглашение',
    to: '/agreement',
  },
  {
    title: 'Политика конфиденциальности',
    to: '/policy',
  },
]
const contacts = [{ title: 'ma@gov-murman.ru', to: 'mailto:ma@gov-murman.ru' }]
const linkBlock = (title, links) => {
  return h('div', {}, [
    h(
      'span',
      { class: 'text-purple-600 mb-4 text-xl sm:text-2xl font-bold' },
      title,
    ),
    ...links.map((l) =>
      h(
        'div',
        {
          class: 'flex gap-3 text-xs sm:text-sm text-dark dark:text-white mt-2',
        },
        h('a', { href: l.to, target: '_blank' }, l.title),
      ),
    ),
  ])
}
</script>
