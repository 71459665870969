import { onUnmounted, ref, unref, watch } from 'vue'

/**
 * `useCountdown` создает таймер обратного отсчета, принимая параметры в виде объекта.
 *
 * @param {object} options - Объект параметров.
 * @param {function} [options.callback] - Опциональная функция, которая будет вызвана после завершения таймера.
 * @param {number} options.seconds - Время обратного отсчета в секундах.
 * @param {boolean} [options.immediate=false] - Указывает, запускать ли таймер сразу после инициализации.
 *
 * @returns {object} - Возвращает объект с методами управления таймером, оставшимся временем (формат 00:00) и флагом выполнения.
 *   - `isPending`: флаг, указывающий, активен ли таймер.
 *   - `start`: метод для запуска таймера.
 *   - `stop`: метод для остановки таймера.
 *   - `remainingTime`: оставшееся время в формате "MM:SS".
 */
export function useCountdown({ callback = null, seconds, immediate = false }) {
  const isPending = ref(false)
  const remainingTime = ref(formatTime(seconds)) // формат оставшегося времени 00:00

  let timer = null
  let countdownTimer = null // таймер для обратного отсчета

  function clear() {
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    if (countdownTimer) {
      clearInterval(countdownTimer)
      countdownTimer = null
    }
  }

  function stop() {
    isPending.value = false
    clear()
  }

  function start(...args) {
    clear()
    isPending.value = true

    let remainingSeconds = seconds // количество оставшихся секунд

    // Запускаем обратный отсчет
    countdownTimer = setInterval(() => {
      remainingSeconds -= 1
      remainingTime.value = formatTime(remainingSeconds)

      if (remainingSeconds <= 0) {
        clearInterval(countdownTimer)
      }
    }, 1000)

    // Основной таймер
    timer = setTimeout(
      () => {
        isPending.value = false
        timer = null
        if (callback) {
          callback(...args) // вызываем callback, если он передан
        }
      },
      unref(seconds) * 1000,
    ) // теперь умножаем на 1000 для преобразования секунд в миллисекунды
  }

  if (immediate) {
    isPending.value = true
    start()
  }

  watch(
    () => unref(seconds),
    () => {
      if (immediate) start()
    },
  )

  onUnmounted(stop)

  // Возвращаем не только управление таймером, но и оставшееся время в формате 00:00
  return {
    isPending,
    start,
    stop,
    remainingTime,
  }
}

// Функция для форматирования времени в формате 00:00
function formatTime(seconds) {
  const minutes = String(Math.floor(seconds / 60)).padStart(2, '0')
  const remainingSeconds = String(seconds % 60).padStart(2, '0')
  return `${minutes}:${remainingSeconds}`
}
