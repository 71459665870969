import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import { onUnmounted, ref, watch } from 'vue'

dayjs.extend(duration)
dayjs.extend(utc)

/**
 * `useCountdownDate` используется для создания обратного отсчета до заданной даты или на заданное количество времени.
 *
 * @param {Date} providedDate - Дата до которой должен идти обратный отсчет.
 *                              Если не передана, будет создан отсчет на основе времени, указанного во втором параметре.
 * @param {number} [time=60] - Время обратного отсчета в минутах, если `providedDate` не передана.
 *                              По умолчанию 60 минут.
 *
 * @returns {object} - Возвращает объект с оставшимися днями, часами, минутами и секундами.
 *                     Также содержит флаг, указывающий на завершение отсчета.
 */
export function useCountdownDate(providedDate, time = 60) {
  const done = ref(false)
  const remainingSeconds = ref(null)
  const formattedTime = ref(`${time}:00`)
  const intervalId = ref(null)

  // Длительность таймера 60 минут (3600 секунд)
  const countdownDuration = time * 60

  // Метод для форматирования времени
  const updateFormattedTime = () => {
    if (remainingSeconds.value !== null) {
      // Проверяем, что оставшееся время определено
      const duration = dayjs.duration(remainingSeconds.value, 'seconds')
      const minutes = String(duration.minutes()).padStart(2, '0')
      const seconds = String(duration.seconds()).padStart(2, '0')
      formattedTime.value = `${minutes}:${seconds}`
    }
  }

  // Метод для расчета оставшегося времени
  const calculateRemainingTime = (startTime) => {
    const now = dayjs.utc()
    const elapsedSeconds = now.diff(startTime, 'seconds')

    // Если прошло больше 60 минут, ставим 0 секунд
    remainingSeconds.value = Math.max(countdownDuration - elapsedSeconds, 0)
  }

  // Запуск таймера
  const startCountdown = () => {
    done.value = false
    if (intervalId.value) return // Избегаем повторного запуска
    intervalId.value = setInterval(() => {
      if (remainingSeconds.value > 0) {
        remainingSeconds.value -= 1
        updateFormattedTime()
      } else {
        done.value = true
        clearInterval(intervalId.value)
      }
    }, 1000)
  }

  // Метод для остановки таймера
  const stopCountdown = () => {
    if (intervalId.value) {
      clearInterval(intervalId.value)
      intervalId.value = null
    }
  }

  // Отслеживаем изменение providedDate
  watch(
    providedDate,
    (newDate) => {
      if (newDate && dayjs(newDate).isValid()) {
        const startTime = dayjs.utc(newDate)

        // Рассчитываем оставшееся время
        calculateRemainingTime(startTime)
        updateFormattedTime()

        // Если еще есть оставшееся время, запускаем таймер
        if (remainingSeconds.value > 0) {
          startCountdown()
        }
      } else {
        // Останавливаем таймер, если дата некорректна
        stopCountdown()
        formattedTime.value = `00:00`
      }
    },
    { immediate: true },
  ) // Запускать watcher сразу

  onUnmounted(() => {
    stopCountdown()
  })

  return {
    done,
    formattedTime,
    stopCountdown,
  }
}
