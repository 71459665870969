<template>
  <div
    v-if="data?.published"
    class="text-black sticky z-[60] border-b bg-white/80 py-4 backdrop-blur dark:!bg-dark/70 dark:text-white"
  >
    <div class="flex items-center justify-center gap-x-4 gap-y-2">
      <p class="items-start px-4 text-sm leading-6 md:items-center md:px-0">
        <strong class="font-bold" v-text="data?.heading" />
        <svg
          viewBox="0 0 2 2"
          class="mx-2 inline h-0.5 w-0.5 fill-current"
          aria-hidden="true"
        >
          <circle cx="1" cy="1" r="1" />
        </svg>
        <span v-text="data?.caption" />
      </p>
      <a
        v-if="data.button && !data.link"
        @click="openWidget"
        href="#"
        class="mr-4 flex rounded-full bg-primary px-3.5 py-1 text-sm text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 dark:bg-gray-600 md:mr-0"
        ><span class="block" v-text="data?.button" />
      </a>
      <a
        v-if="data.button && data.link"
        target="_blank"
        :href="`https://${data.link}`"
        class="mr-4 flex rounded-full bg-primary px-3.5 py-1 text-sm text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 dark:bg-gray-600 md:mr-0"
        ><span class="block" v-text="data?.button" />
      </a>
    </div>
  </div>
</template>

<script setup>
const data = globalThis.BANNER_INFORMATION

function openWidget(e) {
  e.preventDefault()
  e.stopImmediatePropagation()
  document.querySelector('.help-widget').click()
}
</script>
