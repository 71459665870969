import { useQueryClient } from '@tanstack/vue-query'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useRawHttp } from '@/core/http.js'
import useSSRCookies from '@/utils/get-cookies.js'
import isBrowser from '@/utils/is-browser.js'

const defaultOptions = {
  path: '/',
  domain: isBrowser ? window.location.hostname : null,
}

const cookieName = '_macookie'

export const useAuthStore = defineStore('auth', () => {
  const cookies = useSSRCookies()

  const router = useRouter()

  const token = ref(cookies.get(cookieName) ?? null)
  const isImpersonate = ref(
    typeof cookies.get('_impersonate') === 'object' ||
      typeof cookies.get('_impersonate') === 'string',
  )
  const user = ref(null)
  const returnUrl = ref(null)

  const afterLoginHooks = ref([])

  const isLoggedIn = computed(() => !!token.value)

  async function logoutImpersonate(client = null) {
    const returnToken = cookies.get('_impersonate')
    if (returnToken !== null) {
      setCookieToken(returnToken)
      await fetchUser()
      cookies.remove('_impersonate')
      isImpersonate.value = false
    } else {
      logout(true, client)
      cookies.remove('_impersonate')
      isImpersonate.value = false
    }
  }

  const loginRequest = (data) => {
    return useRawHttp('/auth/login', {
      method: 'POST',
      body: data,
    })
  }

  const setCookieToken = (t, remember = true, setImpersonate = false) => {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 1)

    cookies.remove(cookieName, defaultOptions)
    cookies.set(cookieName, t, {
      ...defaultOptions,
      ...(remember
        ? {
            expires: date,
          }
        : {
            maxAge: undefined,
          }),
    })
    token.value = t
    if (setImpersonate) {
      isImpersonate.value = true
    }
  }

  const checkCookie = () => {
    return !!cookies.get(cookieName)
  }

  const getCookieToken = () => {
    return cookies.get(cookieName)
  }

  const login = async (data) => {
    return new Promise((resolve, reject) => {
      loginRequest(data)
        .then(({ token }) => {
          setCookieToken(token, data.remember)
          fetchUser().then(() => {
            if (returnUrl.value !== false) {
              returnUrl.value = null
            }
          })

          resolve()
        })
        .catch((e) => reject(e))
    })
  }

  const loginWithToken = async (token) => {
    setCookieToken(token)
    await fetchUser()
  }

  const linkSocial = (provider, body) => {
    return useRawHttp(`/auth/socials/${provider}`, {
      method: 'POST',
      body,
    })
  }

  const logout = async (redirect = true, client = null) => {
    token.value = null
    user.value = null
    cookies.remove(cookieName, defaultOptions)

    if (redirect) {
      await router.push('/')
      if (client) {
        client.invalidateQueries({
          queryKey: ['personal'],
          refetchType: 'none',
        })
        client.invalidateQueries({ queryKey: ['wallets'], refetchType: 'none' })
        client.invalidateQueries({
          queryKey: ['notifications'],
          refetchType: 'none',
        })
        client.invalidateQueries({ queryKey: ['profile'], refetchType: 'none' })
      }
    }
  }

  const fetchUser = () => {
    return new Promise(async (resolve) => {
      if (token.value) {
        useRawHttp('/auth/profile/me', {
          method: 'GET',
        })
          .then((res) => {
            user.value = res
            resolve()
          })
          .catch(async () => {
            await logout()
            resolve()
          })
      } else {
        await logout(false)
        resolve()
      }
    })
  }

  return {
    token,
    user,
    returnUrl,
    isLoggedIn,
    isImpersonate,
    afterLoginHooks,
    login,
    loginWithToken,
    logout,
    fetchUser,
    linkSocial,
    getCookieToken,
    setCookieToken,
    checkCookie,
    logoutImpersonate,
  }
})
