import { useDateFormat } from '@vueuse/core'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { getUserTimezone } from '@/utils/user-timezone.js'

dayjs.locale('ru')
dayjs.extend(utc)
dayjs.extend(timezone)

export function getDateFormat(date, format = 'DD.MM.YYYY') {
  const tz = getUserTimezone()
  return dayjs(date).tz(tz).format(format)
}

export function getHumanDate(date) {
  const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ]

  return useDateFormat(
    date,
    `DD ${months[parseInt(useDateFormat(date, 'M').value) - 1]}`,
  ).value
}

export function compareDates(
  date1,
  date2,
  formats = {
    same: 'DD.MM.YYYY',
    differentTime: 'DD.MM.YYYY',
    different: 'DD.MM.YYYY HH:mm',
  },
) {
  const tz = getUserTimezone()
  const timeFormat = 'HH:mm'

  const d1 = dayjs(date1).tz(tz)
  const d2 = dayjs(date2).tz(tz)

  // Если даты одинаковые
  if (d1.isSame(d2)) {
    return d1.format(`${formats.same}`)
  }

  // Если даты одинаковы, но время разное
  if (d1.isSame(d2, 'day')) {
    return `${d1.format(formats.differentTime)} ${d1.format(timeFormat)} — ${d2.format(timeFormat)}`
  }

  // Если даты разные
  return `${d1.format(formats.different)} — ${d2.format(formats.different)}`
}

export function getDateFromPeriod(from, to) {
  let res = ''
  const now = new Date()
  const start = new Date(from)

  if (getDateFormat(from, 'DD.MM.YYYY') === getDateFormat(to, 'DD.MM.YYYY')) {
    res += getHumanDate(from) + getDateFormat(start, ' в HH:mm')
  } else if (now > start) {
    res += 'до ' + getHumanDate(to)
  } else {
    res += 'c ' + getHumanDate(from)
  }

  return res
}
