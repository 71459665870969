<script setup>
import { IconChevronDown } from '@tabler/icons-vue'

const props = defineProps({
  icon: {
    type: [Object, Function],
    default: null,
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Выбрано',
  },
  modelValue: {
    type: Array,
    default: [],
  },
  max: {
    type: Number,
    default: 2,
  },
  labelBy: {
    type: String,
    default: 'label',
  },
})
</script>

<template>
  <button
    class="inline-flex w-full items-stretch gap-2 whitespace-nowrap rounded-md bg-block px-3 py-4 text-sm font-semibold transition-colors hover:bg-black-100 hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 dark:bg-black-800 dark:hover:bg-black-900"
  >
    <span class="flex items-center gap-2">
      <component
        v-if="icon"
        :is="icon"
        class="size-5 flex-none text-black-500 dark:text-black-300"
      />
      <span v-if="label" v-text="label" />
    </span>
    <span class="flex-1">
      <span
        v-if="modelValue && modelValue.length"
        class="flex items-center space-x-1 overflow-hidden border-l border-black-200/80 pl-2 dark:border-black-200/40"
      >
        <span
          v-if="modelValue && modelValue.length <= props.max"
          v-for="(item, i) in modelValue"
          :key="i"
          class="block w-full max-w-[60px] items-center overflow-hidden truncate rounded-sm border border-transparent bg-secondary px-1 py-0.5 text-xs font-normal text-secondary-foreground transition-colors hover:bg-secondary/80 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 md:max-w-[120px]"
        >
          {{ item[labelBy] || item }}
        </span>
        <span
          v-else
          v-text="
            `${placeholder} ${modelValue && modelValue.length ? modelValue.length : ''}`
          "
        />
      </span>
    </span>
    <IconChevronDown
      class="size-5 flex-none text-black-500 dark:text-black-300"
    />
  </button>
</template>

<style scoped></style>
