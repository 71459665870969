import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'

import {
  IconBell,
  IconBolt,
  IconClipboardList,
  IconKey,
  IconMessageChatbot,
  IconQrcode,
  IconShoppingBag,
  IconTicket,
  IconTrashX,
  IconUser,
} from '@tabler/icons-vue'

import Default from '@/layouts/default.vue'
import Empty from '@/layouts/empty.vue'
import isBrowser from '@/utils/is-browser.js'

import { useAuthStore } from '../stores/auth.js'

let routes = [
  {
    path: '/profile',
    component: () => import('@/pages/profile/index.vue'),
    name: 'profile',
    meta: {
      layout: Default,
      footer: false,
      auth: true,
    },
    children: [
      {
        path: '',
        group: 'information',
        label: 'Личные данные',
        icon: IconUser,
        name: 'profile.home',
        component: () => import('@/pages/profile/sub-pages/home.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'auth',
        group: 'information',
        label: 'Авторизация',
        icon: IconKey,
        name: 'profile.auth',
        component: () => import('@/pages/profile/sub-pages/auth.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'notifications',
        group: 'information',
        label: 'Уведомления',
        icon: IconBell,
        name: 'profile.notifications',
        component: () => import('@/pages/profile/sub-pages/notifications.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'support',
        group: 'activity',
        label: 'Сообщения',
        icon: IconMessageChatbot,
        name: 'profile.support',
        meta: {
          auth: true,
        },
        children: [
          {
            path: '',
            name: 'profile.support.index',
            component: () =>
              import('@/pages/profile/sub-pages/support/index.vue'),
          },
          {
            path: ':id(\\d+)',
            name: 'profile.support.id',
            component: () =>
              import('@/pages/profile/sub-pages/support/[id].vue'),
          },
        ],
      },
      {
        path: 'referral',
        group: 'activity',
        label: 'Приглашения',
        icon: IconQrcode,
        name: 'profile.referral',
        component: () => import('@/pages/profile/sub-pages/referral.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'transactions',
        group: 'activity',
        label: 'Баллы',
        icon: IconBolt,
        name: 'profile.transactions',
        component: () => import('@/pages/profile/sub-pages/transactions.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'tickets',
        group: 'activity',
        label: 'Билеты',
        icon: IconTicket,
        name: 'profile.tickets',
        component: () => import('@/pages/profile/sub-pages/tickets.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'orders',
        group: 'activity',
        label: 'Заказы',
        icon: IconShoppingBag,
        name: 'profile.orders',
        component: () => import('@/pages/profile/sub-pages/orders.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'visit-history',
        group: 'activity',
        label: 'История посещений',
        icon: IconClipboardList,
        name: 'profile.visit_history',
        component: () => import('@/pages/profile/sub-pages/visitHistory.vue'),
        meta: {
          auth: true,
        },
      },
      // {
      //   path: 'delete',
      //   label: 'Удалить аккаунт',
      //   icon: IconTrashX,
      //   name: 'profile.delete',
      //   component: () => import('@/pages/profile/sub-pages/delete.vue'),
      //   meta: {
      //     auth: true,
      //   },
      // },
    ],
  },
  {
    path: '/',
    component: () => import('@/pages/index.vue'),
    name: 'index',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/blog',
    children: [
      {
        path: '',
        name: 'blog',
        component: () => import('@/pages/blog/index.vue'),
        meta: {
          layout: Default,
        },
      },
      {
        path: ':id(\\d+)+',
        component: () => import('@/pages/blog/[id].vue'),
        name: 'blog.id',
        meta: {
          layout: Default,
        },
      },
    ],
  },
  {
    path: '/afisha',
    children: [
      {
        path: '',
        name: 'afisha',
        component: () => import('@/pages/afisha/index.vue'),
        meta: {
          layout: Default,
        },
      },
      {
        path: ':id(\\d+)+',
        component: () => import('@/pages/afisha/[id].vue'),
        name: 'afisha.id',
        meta: {
          layout: Default,
        },
      },
    ],
  },
  // {
  //   path: '/grants',
  //   children: [
  //     {
  //       path: '',
  //       name: 'grants',
  //       component: () => import('@/pages/grants/index.vue'),
  //       meta: {
  //         layout: Default,
  //       },
  //     },
  //     {
  //       path: ':id(\\d+)+',
  //       name: 'grants.id',
  //       component: () => import('@/pages/grants/[id].vue'),
  //       meta: {
  //         layout: Default,
  //       },
  //     },
  //   ],
  // },
  {
    path: '/tasks',
    children: [
      {
        path: '',
        name: 'tasks',
        component: () => import('@/pages/tasks/index.vue'),
        meta: {
          layout: Default,
        },
      },
    ],
  },
  {
    path: '/spaces',
    name: 'spaces',
    children: [
      {
        path: '',
        name: 'spaces.index',
        component: () => import('@/pages/spaces/index.vue'),
        meta: {
          layout: Default,
        },
      },
      {
        path: ':id(\\d+)+',
        component: () => import('@/pages/spaces/[id]/index.vue'),
        name: 'spaces.id',
        meta: {
          layout: Default,
        },
      },
      {
        path: ':id(\\d+)+/visit',
        component: () => import('@/pages/spaces/[id]/visit.vue'),
        name: 'spaces.visit',
        meta: {
          layout: Default,
          header: false,
          footer: false,
        },
      },
      {
        path: ':id(\\d+)+/visit/:visitId/feedback',
        component: () => import('@/pages/spaces/[id]/feedback.vue'),
        name: 'spaces.visit.feedback',
        meta: {
          layout: Default,
          header: false,
          footer: false,
        },
      },
    ],
  },
  {
    path: '/jobs',
    name: 'jobs',
    children: [
      {
        path: '',
        component: () => import('@/pages/jobs/index.vue'),
        name: 'jobs.index',
        meta: {
          layout: Default,
        },
      },
      {
        path: ':id(\\d+)+',
        component: () => import('@/pages/jobs/[id].vue'),
        name: 'jobs.id',
        meta: {
          layout: Default,
        },
      },
    ],
  },
  {
    path: '/creations',
    name: 'creations',
    children: [
      {
        path: '',
        component: () => import('@/pages/creations/index.vue'),
        name: 'creations.index',
        meta: {
          layout: Default,
        },
      },
      {
        path: ':id(\\d+)+',
        component: () => import('@/pages/creations/[id].vue'),
        name: 'creations.id',
        meta: {
          layout: Default,
        },
      },
    ],
  },
  {
    path: '/vacancies',
    name: 'vacancies',
    children: [
      {
        path: '',
        component: () => import('@/pages/vacancies/index.vue'),
        name: 'vacancies.index',
        meta: {
          layout: Default,
        },
      },
      {
        path: ':id(\\d+)+',
        component: () => import('@/pages/vacancies/[id].vue'),
        name: 'vacancies.id',
        meta: {
          layout: Default,
        },
      },
    ],
  },
  {
    path: '/partners',
    name: 'partners',
    children: [
      {
        path: '',
        component: () => import('@/pages/partners/index.vue'),
        name: 'partners.index',
        meta: {
          layout: Default,
        },
      },
      {
        path: ':id(\\d+)+',
        component: () => import('@/pages/partners/[id].vue'),
        name: 'partners.id',
        meta: {
          layout: Default,
        },
      },
    ],
  },
  {
    path: '/media',
    name: 'media',
    children: [
      {
        path: '',
        component: () => import('@/pages/media/index.vue'),
        name: 'media.index',
        meta: {
          layout: Default,
        },
      },
      {
        path: ':id(\\d+)+',
        component: () => import('@/pages/media/[id].vue'),
        name: 'media.id',
        meta: {
          layout: Default,
        },
      },
    ],
  },
  {
    path: '/shop',
    name: 'shop',
    children: [
      {
        path: '',
        component: () => import('@/pages/shop/index.vue'),
        name: 'shop.index',
        meta: {
          layout: Default,
        },
      },
      {
        path: ':id(\\d+)+',
        component: () => import('@/pages/shop/[id].vue'),
        name: 'shop.id',
        meta: {
          layout: Default,
        },
      },
    ],
  },
  {
    path: '/magic',
    component: () => import('@/pages/magic.vue'),
    name: 'magic',
    meta: {
      layout: Empty,
    },
  },
  {
    path: '/impersonate',
    component: () => import('@/pages/impersonate.vue'),
    name: 'impersonate',
    meta: {
      layout: Empty,
    },
  },
  {
    path: '/agreement',
    component: () => import('@/pages/agreement.vue'),
    name: 'agreement',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/policy',
    component: () => import('@/pages/policy.vue'),
    name: 'policy',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/socials/:social',
    component: () => import('@/pages/socials.vue'),
    name: 'socials',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/mobile',
    name: 'mobile',
    meta: {
      layout: Empty,
    },
    children: [
      {
        path: 'register',
        component: () => import('@/pages/mobile/register.vue'),
        name: 'mobile.register',
        meta: {
          layout: Default,
        },
      },
      {
        path: 'login',
        component: () => import('@/pages/mobile/login.vue'),
        name: 'mobile.login',
        meta: {
          layout: Default,
        },
      },
      {
        path: 'reset',
        component: () => import('@/pages/mobile/reset.vue'),
        name: 'mobile.reset',
        meta: {
          layout: Default,
        },
      },
    ],
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  if (from.name && (from.name.startsWith(to.name) || from.name === to.name)) {
    return savedPosition || to.meta?.scrollPos
  }
  return savedPosition || { left: 0, top: 0 }
}

export default function () {
  const router = createRouter({
    history: isBrowser ? createWebHistory('/') : createMemoryHistory('/'),
    strict: true,
    routes: [
      ...routes,
      {
        path: '/:pathMatch(.*)*',
        component: () => import('@/pages/404.vue'),
        name: '404',
        meta: {
          layout: Default,
          footer: false,
        },
      },
    ],
    scrollBehavior,
  })

  router.beforeResolve(async () => {
    if (useAuthStore().token && !useAuthStore().user) {
      await useAuthStore().fetchUser()
    }
  })

  router.beforeEach((to, from, next) => {
    if (from.meta?.scrollPos && to.name && to.name.startsWith(from.name)) {
      from.meta.scrollPos.top = window.scrollY
      from.meta.scrollPos.left = 0
    }
    next()
  })

  const middleware = import.meta.glob('@/middleware/*.js')

  for (const path in middleware) {
    middleware[path]().then((m) => {
      router.beforeResolve((t, f) => m.default(t, f))
    })
  }

  return router
}
