<template>
  <div
    class="flex w-full min-w-0 items-center gap-2 rounded-md bg-white px-4 py-2 ring-1 ring-black-100 dark:bg-purple-950 dark:text-white"
  >
    <component
      class="text-primary-600 dark:text-primary-200 mr-2 size-6 flex-none"
      :is="icons[context.mimeType] || IconFile"
    />
    <div class="flex min-w-0 flex-1 flex-col justify-between">
      <span
        class="truncate text-sm font-medium"
        :title="context.name"
        v-text="context.name"
      />
      <div class="flex items-center gap-2">
        <p
          class="whitespace-nowrap text-xs opacity-50"
          v-text="getHumanSizeOptimized(context.size)"
        />
        <div
          class="truncate text-xs text-rose-500"
          :title="context.message || 'Ошибка'"
          v-if="context.status === 'error'"
          v-text="context.message || 'Ошибка'"
        ></div>
        <div class="text-xs text-slate-500" v-if="context.status === 'waiting'">
          Ожидание загрузки
        </div>
        <div
          v-if="context?.status === 'pending'"
          class="flex w-full items-center"
        >
          <div class="relative h-1 w-full overflow-hidden rounded bg-slate-400">
            <div
              :style="`width: ${context.progress}%`"
              class="h-full w-0 bg-blue-600 transition-all"
            >
              <div class="flare" />
            </div>
          </div>
          <div
            class="w-9 flex-none text-right text-xs"
            v-text="`${context.progress}%`"
          />
        </div>
      </div>
    </div>
    <div class="relative z-10 flex gap-2">
      <button
        v-if="context.status === 'error'"
        title="Повторить загрузку"
        :class="buttonClass"
        @click.prevent="$emit('reload')"
      >
        <IconReload class="m-auto size-4" />
      </button>
      <button
        v-if="
          context.status === 'success' ||
          context.status === 'error' ||
          !context.status
        "
        title="Удалить файл"
        :class="buttonClass"
        @click.prevent="$emit('remove')"
      >
        <IconX class="m-auto size-4" />
      </button>
      <button
        v-if="context.status === 'pending'"
        title="Остановить загрузку"
        :class="buttonClass"
        @click.prevent="$emit('stop')"
      >
        <IconPlayerStop class="m-auto size-4" />
      </button>
    </div>
  </div>
</template>

<script setup>
import {
  IconFile,
  IconFileTypeDoc,
  IconFileTypePdf,
  IconFileTypePpt,
  IconFileTypeXls,
  IconFileTypeZip,
  IconMovie,
  IconPhoto,
  IconPlayerStop,
  IconReload,
  IconVinyl,
  IconX,
} from '@tabler/icons-vue'

const icons = {
  pdf: IconFileTypePdf,
  excel: IconFileTypeXls,
  word: IconFileTypeDoc,
  ppt: IconFileTypePpt,
  image: IconPhoto,
  video: IconMovie,
  archive: IconFileTypeZip,
  audio: IconVinyl,
}

const buttonClass = 'size-6 flex rounded-full dark:bg-primary-1000 bg-black/10'

defineProps({
  context: {
    type: Object,
    default: {},
    require: true,
  },
})

defineEmits(['remove', 'reload', 'stop'])

function getHumanSizeOptimized(fileSizeInBytes) {
  const byteUnits = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const base = 1024
  let i = Math.floor(Math.log(fileSizeInBytes) / Math.log(base))
  fileSizeInBytes /= Math.pow(base, i)
  return `${fileSizeInBytes.toFixed(2)} ${byteUnits[i]}`
}
</script>
<style scoped>
.flare {
  position: absolute;
  top: 0;
  height: 100%;
  width: 15px;
  transform: skewX(-15deg);
  left: -150%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.4)
  );
  animation: flareAnimation 1.2s infinite linear;
}

@keyframes flareAnimation {
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
}
</style>
