export const fade = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0',
  enterToClass: 'opacity-100',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0',
}

export const fadeScale = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
  enterToClass: 'opacity-100 translate-y-0 sm:scale-100',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100 translate-y-0 sm:scale-100',
  leaveToClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
}

export const fadeShiftScale = {
  enterActiveClass: 'ease-[cubic-bezier(.1,.06,.01,1.25)] duration-300',
  enterFromClass: 'translate-y-1/3 translate-x-1/3 scale-[0.3] opacity-0',
  enterToClass: 'opacity-100 translate-y-0 translate-x-0 scale-100',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100 scale-100',
  leaveToClass: 'opacity-0 scale-0 translate-y-1/2 translate-x-1/2',
}
export const fadeSlideLeft = {
  enterActiveClass: 'ease-out duration-200',
  enterFromClass: 'opacity-0 translate-x-full',
  enterToClass: 'opacity-100 translate-x-0',
  leaveActiveClass: 'ease-in duration-100',
  leaveFromClass: 'opacity-100 translate-x-0',
  leaveToClass: 'opacity-0 -translate-x-full',
}

export const fadeSlideRight = {
  enterActiveClass: 'ease-out duration-200',
  enterFromClass: 'opacity-0 -translate-x-full',
  enterToClass: 'opacity-100 translate-x-0',
  leaveActiveClass: 'ease-in duration-100',
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0 translate-x-full',
}
