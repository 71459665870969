<script setup>
import { ref } from 'vue'

import Login from '@/components/auth/Login.vue'
import Register from '@/components/auth/Register.vue'
import Reset from '@/components/auth/Reset.vue'

const step = ref('login')

const state = {
  login: Login,
  register: Register,
  reset: Reset,
}

function navigate(value) {
  step.value = value
}
</script>

<template>
  <Component :is="state[step]" @navigate="navigate" @close="$emit('close')" />
</template>
