<script setup>
import { autoUpdate, offset, useFloating } from '@floating-ui/vue'
import { ref } from 'vue'
import { VueFinalModal } from 'vue-final-modal'

import { IconX } from '@tabler/icons-vue'

defineProps({
  containerClass: {
    type: String,
    default: '',
  },
})

const modalContainerRef = ref()
const floatingRef = ref()

const { floatingStyles } = useFloating(modalContainerRef, floatingRef, {
  placement: 'top-end',
  middleware: [offset({ mainAxis: 16 })],
  whileElementsMounted: autoUpdate,
})

const overlayTransition = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0',
  enterToClass: 'opacity-100',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0',
}

const contentTransition = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
  enterToClass: 'opacity-100 translate-y-0 sm:scale-100',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100 translate-y-0 sm:scale-100',
  leaveToClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
}
</script>

<template>
  <VueFinalModal
    overlay-class="backdrop-blur-sm"
    content-class="absolute inset-0"
    :content-transition="contentTransition"
    :overlay-transition="overlayTransition"
    @update:model-value="(val) => $emit('update:model-value', val)"
  >
    <div ref="floatingRef" class="absolute" :style="floatingStyles">
      <IconX class="mr-2 size-4 text-white md:mr-0 md:translate-x-full" />
    </div>
    <div
      class="absolute inset-0 flex h-full overflow-auto px-2 py-14"
      @mousedown.self="() => $emit('update:model-value', false)"
    >
      <div
        ref="modalContainerRef"
        class="relative overflow-hidden rounded-3xl bg-background"
        :class="containerClass"
      >
        <slot />
      </div>
    </div>
  </VueFinalModal>
</template>
