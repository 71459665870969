<script setup>
import VueDatePicker from '@vuepic/vue-datepicker'
import { vMaska } from 'maska/vue'
import { ref } from 'vue'

import { IconCalendar, IconX } from '@tabler/icons-vue'

import Button from '@/components/glass/button/index.js'
import useTheme from '@/core/theme.js'

const isDark = useTheme()

const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
})

const datepicker = ref(null)

const isMenuOpen = ref(false)

function toggleMenu() {
  !isMenuOpen.value ? datepicker.value.openMenu() : datepicker.value.closeMenu()
}

function replaceLettersWithHashes(formatString) {
  return formatString.replace(/[a-zA-Z]/g, '#')
}

function getFormat() {
  if (props.context.range && props.context.timePicker) return 'HH:mm - HH:mm'
  if (props.context.range) return 'dd.MM.yy'
  if (props.context.monthPicker) return 'MM.yyyy'
  if (props.context.timePicker) return 'HH:mm'
  if (props.context.enableTimePicker) return 'dd.MM.yyyy HH:mm'
  return 'dd.MM.yyyy'
}

const model = ref(props.context.value || '')

const componentOptions = ref({
  variant: props.context.variant || 'outlined',
  placeholder: props.context.placeholder || '13.05.1997',
  range: props.context.range || false,
  presetRanges: props.context.presetRanges || [],
  minRange: props.context.minRange || null,
  maxRange: props.context.maxRange || null,
  enableTimePicker: props.context.enableTimePicker || false,
  timePicker: props.context.timePicker || false,
  weekPicker: props.context.weekPicker || false,
  monthPicker: props.context.monthPicker || false,
  yearPicker: props.context.yearPicker || false,
  utc: props.context.utc || 'preserve',
  locale: props.context.locale || 'ru-RU',
  is24: props.context.is24 || true,
  enableSeconds: props.context.enableSeconds || false,
  monthNameFormat: props.context.monthNameFormat || 'short',
  hideInputIcon: props.context.hideInputIcon || false,
  clearable: props.context.clearable || true,
  closeOnScroll: props.context.closeOnScroll || false,
  autoApply: props.context.autoApply ?? true,
  markers: props.context.markers || [],
  highlight: props.context.highlight || [],
  highlightWeekDays: props.context.highlightWeekDays || null,
  highlightDisabledDays: props.context.highlightDisabledDays || false,
  disabled: props.context.disabled || false,
  readonly: props.context.readonly || false,
  noSwipe: props.context.noSwipe || false,
  cancelText: props.context.cancelText || 'Cancel',
  selectText: props.context.selectText || 'Submit',
  format: props.context.format || getFormat(),
  modelAuto: props.context.modelAuto ?? false,
  // Enable manual input
  textInput: props.context.textInput ?? {
    openMenu: false,
  },
})

const classes = ref(props.context.classes.input)
const iconClasses = ref('size-5')
const textClasses = ref('hover:text-primary')
const activeClasses = ref('text-primary')

if (componentOptions.value.variant === 'filled') {
  classes.value =
    'inline-flex w-full gap-2 pl-9 items-stretch whitespace-nowrap font-semibold text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-block dark:bg-black-800 dark:hover:bg-black-900 hover:bg-black-100 hover:text-accent-foreground rounded-md px-3 py-4'
  textClasses.value = 'text-black-500 dark:text-black-300 flex-none'
}

function updateModelValue(value) {
  if (!model.value) {
    props.context.node.input(null)
    return
  }
  props.context.node.input(model.value)
}
</script>

<template>
  <VueDatePicker
    ref="datepicker"
    v-model="model"
    :dark="isDark"
    v-bind="componentOptions"
    @open="isMenuOpen = true"
    @closed="isMenuOpen = false"
    @update:model-value="updateModelValue"
  >
    <template #action-buttons>
      <Button
        class="w-full"
        text="Выбрать"
        size="sm"
        @click.prevent="datepicker.selectDate()"
      />
    </template>
    <template #action-preview></template>
    <template #dp-input="{ value, onInput, onEnter, onTab, onBlur }">
      <div>
        <input
          class="w-full"
          :class="classes"
          v-maska="{
            mask: componentOptions.range
              ? ['##.##.##', '##.##.## - ##.##.##']
              : '##.##.####',
          }"
          type="text"
          :value="value"
          :placeholder="componentOptions.placeholder"
          @keydown.tab="onTab"
          @keydown.enter="onEnter"
          @blur="onBlur"
          @input="onInput"
        />
      </div>
    </template>
    <template #input-icon>
      <button class="p-2" @click.stop.prevent="toggleMenu">
        <IconCalendar
          class="transition-colors"
          :class="[iconClasses, textClasses, isMenuOpen ? activeClasses : '']"
        />
      </button>
    </template>
    <template #clear-icon="{ clear }">
      <button class="p-2" @click="clear">
        <IconX class="size-4" />
      </button>
    </template>
  </VueDatePicker>
</template>
