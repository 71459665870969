<template>
  <div class="flex flex-col">
    <div>
      <Heading
        title="Опишите возникшую проблему"
        tag="2"
        styles="3"
        class="mb-4"
      />
      <p class="text-black-600 dark:text-black-400">
        Также Вы можете оставить отзывы или предложения по работе сайта. Мы
        постараемся ответить в ближайшее время
      </p>

      <div class="flex-1 flex-col">
        <FormKit
          type="form"
          :actions="false"
          class="my-4"
          id="report"
          form-class="my-4"
          v-model="form"
        >
          <FormKit
            v-if="!auth.isLoggedIn"
            type="email"
            name="email"
            placeholder="example@mail.com"
            label="Email"
          />

          <FormKit
            type="dropdown"
            name="type"
            placeholder="Другое"
            :options="reportTypes"
            label="Тема обращения"
          />

          <FormKit
            type="text"
            name="title"
            placeholder="Заголовок"
            label="Заголовок"
          />

          <FormKit
            type="textarea"
            name="content"
            placeholder="Опишите вашу проблему"
            label="Описание проблемы"
            :classes="{ input: '!h-48 resize-none' }"
          />

          <FormKit
            type="file"
            name="media"
            label="Файл"
            :loading="disableIfLoading"
          />
        </FormKit>
      </div>

      <div class="mt-8 flex w-full justify-end gap-4">
        <Button
          class="flex-1 md:flex-none"
          size="lg"
          variant="outline"
          text="Отменить"
          @click="$emit('close')"
        />
        <Button
          class="flex-1 md:flex-none"
          size="lg"
          :loading="submitMutation.isPending.value || filesLoading"
          text="Отправить"
          @click="submitMutation.mutate(form)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { setErrors } from '@formkit/vue'
import { useMutation } from '@tanstack/vue-query'
import { ref } from 'vue'
import { toast } from 'vue-sonner'

import { useAuthStore } from '@/stores/auth.js'

import Button from '@/components/glass/button'
import Heading from '@/components/glass/heading'
import useHttp from '@/core/http.js'

const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
})

const emit = defineEmits(['close'])

const auth = useAuthStore()

const filesLoading = ref(false)

function disableIfLoading(value) {
  filesLoading.value = value
}

const reportTypes = ref([
  {
    label: 'Авторизация',
    value: 'auth',
  },
  {
    label: 'Работа сайта',
    value: 'web',
  },
  {
    label: 'Мобильное приложение',
    value: 'mobile',
  },
  {
    label: 'Начисление баллов',
    value: 'points',
  },
  {
    label: 'Удаление аккаунта',
    value: 'delete',
  },
  {
    label: 'Другое',
    value: 'not_matched',
  },
])

const form = ref({
  email: auth.user?.email,
  type: 'web',
})

const submitMutation = useMutation({
  mutationFn: (form) =>
    useHttp('/appeals', {
      method: 'POST',
      body: form,
    }),
  onSuccess: () => {
    emit('close')
    toast.success('Обращение отправлено', {
      description: 'В ближайшее время на ваше обращение поступит ответ',
    })
  },
  onError: (e) => {
    if (e.data?.errors) {
      setErrors('report', e.data?.errors)
    }
  },
})
</script>
