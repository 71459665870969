import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/vue-query'
import { watchDebounced } from '@vueuse/core'
import { onServerPrefetch, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import useHttp from '@/core/http.js'
import { lzCompress, lzDecompress } from '@/utils/lz-compression.js'
import { useQueryEvents } from '@/utils/useQueryEvents.js'

function fetcher({
  endpoint,
  pageParam = 0,
  search,
  filters,
  perPage = undefined,
}) {
  return useHttp(`${endpoint}`, {
    query: {
      filters: filters && filters.value ? lzCompress(filters.value) : '',
      query: search.value,
      page: pageParam,
      per_page: perPage,
    },
  })
}

/**
 * useInfiniteCatalog - Custom hook to fetch infinite paginated catalog data.
 *
 * @param {string} endpoint - The API endpoint to fetch the catalog data from.
 * @param {function} select - A function to select or transform the data.
 * @param {object} fetchQuery - A object to change fetch query.
 */

export function useInfiniteCatalog({
  endpoint,
  perPage,
  select,
  ssrCache = true,
  queryKey,
  enabled = true,
}) {
  const route = useRoute()
  const page = ref(1)
  const search = ref(undefined)
  const filters = ref({})
  const pageState = ref({})

  watchDebounced(search, () => state.refetch(), {
    debounce: 250,
    maxWait: 500,
  })

  watch(
    () => route.query,
    (value) => {
      if (value.filters) {
        let includedTags = []
        let newValue = lzDecompress(value.filters)

        if (route.path === '/jobs')
          includedTags = [
            'schedule',
            'employment',
            'specialisation',
            'education',
          ]

        if (includedTags.length) {
          for (const tag of includedTags) {
            if (newValue[tag]) {
              newValue['tags'] = [...(newValue.tags ?? []), ...newValue[tag]]
              delete newValue[tag]
            }
          }
        }

        filters.value = newValue
      } else {
        filters.value = {}
      }
    },
    {
      immediate: true,
    },
  )

  watchDebounced(filters, () => state.refetch(), {
    debounce: 250,
    maxWait: 500,
  })

  function nextPage() {
    if (!state.hasNextPage.value || state.isFetchingNextPage.value) return
    state.fetchNextPage()
  }

  /**
   * toPage
   *
   * @param {number} pageNumber
   */
  function toPage(pageNumber) {
    page.value = pageNumber
  }

  const state = reactive(
    useInfiniteQuery({
      queryKey: queryKey ?? [route.name],
      enabled,
      queryFn: ({ pageParam }) =>
        fetcher({ endpoint, pageParam, perPage, search, filters }),
      initialPageParam: 1,
      select: (data) => {
        const { total, last_page } = data

        pageState.value = {
          total: total,
          lastPage: last_page,
        }

        return select ? select(data) : data
      },
      getNextPageParam: (lastPage, pages) => {
        page.value = lastPage.current_page
        return lastPage.current_page + 1 > lastPage.last_page
          ? undefined
          : lastPage.current_page + 1
      },
      staleTime: ssrCache ? 1000 : 0,
    }),
  )

  onServerPrefetch(state.suspense)

  return {
    state,
    page,
    search,
    pageState,
    nextPage,
    toPage,
  }
}

/**
 * useCatalog - Custom hook to fetch paginated catalog data.
 *
 * @param {string} endpoint - The API endpoint to fetch the catalog data from.
 * @param {function} select - A function to select or transform the data.
 * @param {object} fetchQuery - A object to change fetch query.
 */

export function useCatalog({
  endpoint,
  perPage,
  select,
  queryKey,
  filters = {},
  ssrCache = true,
  enabled,
  queryOptions,
  callbacks = {},
}) {
  const route = useRoute()
  const page = ref(1)
  const search = ref('undefined')
  const total = ref(0)
  const pageState = ref({})

  watchDebounced(search, () => state.refetch(), {
    debounce: 250,
    maxWait: 500,
  })

  function prevPage() {
    page.value = Math.max(page.value - 1, 1)
  }

  function nextPage() {
    page.value = page.value + 1
  }

  /**
   * toPage
   *
   * @param {number} pageNumber
   */
  function toPage(pageNumber) {
    page.value = pageNumber
  }

  const state = useQuery({
    enabled,
    queryKey: queryKey ? [queryKey, page] : [route.name, page, queryKey],
    queryFn: () =>
      fetcher({ endpoint, pageParam: page.value, search, perPage, filters }),
    placeholderData: keepPreviousData,
    select: (data) => {
      const { total, last_page } = data

      pageState.value = {
        total: total,
        lastPage: last_page,
      }

      return select ? select(data) : data
    },
    staleTime: ssrCache ? 1000 : 0,
    retry: 0,
    ...queryOptions,
  })

  useQueryEvents(state, callbacks)

  onServerPrefetch(state.suspense)

  return {
    state,
    page,
    search,
    pageState,
    prevPage,
    nextPage,
    toPage,
  }
}
