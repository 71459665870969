const innerDefault =
  'flex items-center ring-1 bg-transparent ring-black-100 dark:ring-black-500 focus-within:ring-primary focus-within:ring-2 [&>label:first-child]:focus-within:text-slate-600 rounded-md mb-1'

export default {
  global: {
    fieldset: 'max-w-md',
    help: 'text-xs text-gray-500',
    inner:
      'flex transition-shadow mb-1 rounded-md bg-transparent ring-1 ring-black-100 focus-within:ring-2 focus-within:ring-primary dark:ring-black-500 dark:focus-within:ring-primary formkit-disabled:bg-black-100 formkit-disabled:dark:bg-slate-950 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
    input:
      'rounded-md w-full py-4 leading-5 font-medium px-3 appearance-none bg-transparent dark:text-white rounded autoFillInputLightModeOverride dark:autoFillInputDarkModeOverride focus:outline-none focus:ring-0 focus:shadow-none',
    label: 'block mb-1.5 text-sm',
    legend: 'block mb-1.5 text-sm',
    loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
    message: 'text-rose-500 mb-1 text-xs',
    messages: 'list-none p-0 mt-1 mb-0',
    outer: 'mb-4 formkit-disabled:opacity-50',
    prefixIcon:
      'w-10 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl border-r border-gray-400 bg-white bg-gradient-to-b from-transparent to-gray-200 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon:
      'w-7 pr-3 p-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
  },

  // Family styles apply to all inputs that share a common family
  'family:box': {
    decorator:
      'block relative size-[18px] rounded text-transparent peer-checked:text-primary-foreground peer-checked:animate-shrink-bounce before:transition-all before:absolute before:inset-0 before:rounded before:ring-0 before:ring-primary peer-checked:before:ring-[1rem] before:ring-inset before:duration-500',
    decoratorIcon:
      'flex p-0.5 size-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
    help: 'mb-2 mt-1.5',
    input:
      '$reset absolute size-0 overflow-hidden opacity-0 pointer-events-none peer',
    inner:
      '$reset rounded-xs mt-px cursor-pointer ring-1 ring-black-100 focus-within:ring-2 focus-within:ring-primary dark:ring-black-500 dark:focus-within:ring-primary transition-shadow',
    label: '$reset text-base leading-5 ml-2 cursor-pointer',
    wrapper: 'flex items-start mb-3 select-none',
  },

  'family:button': {
    input:
      '$reset inline-flex items-center bg-blue-600 text-white text-sm font-normal py-3 px-6 rounded focus-visible:outline-2 focus-visible:outline-blue-600 focus-visible:outline-offset-2 formkit-disabled:bg-gray-400 formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin',
    wrapper: 'mb-1',
    prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
    suffixIcon: '$reset block w-4 ml-2 stretch',
  },

  'family:dropdown': {
    dropdownWrapper:
      'my-2 w-full shadow-lg rounded [&::-webkit-scrollbar]:hidden',
    emptyMessageInner:
      'flex items-center justify-center text-sm p-2 text-center w-full text-gray-500 [&>span]:mr-3 [&>span]:ml-0',
    // inner: innerDefault,
    input:
      'w-full px-4 py-2.5 border-none text-base text-black-700 dark:text-white placeholder-black-300',
    listbox: 'bg-white shadow-lg rounded overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem:
      'pl-7 relative hover:bg-gray-300 data-[is-active="true"]:bg-gray-300 data-[is-active="true"]:aria-selected:bg-blue-600 aria-selected:bg-blue-600 aria-selected:text-white',
    loaderIcon: 'ml-auto',
    loadMoreInner:
      'flex items-center justify-center text-sm p-2 text-center w-full text-primary formkit-loading:text-gray-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
    option: 'p-2.5',
    optionLoading: 'text-gray-500',
    placeholder: 'p-2.5 text-gray-400',
    selector: 'flex w-full justify-between items-center [&u]',
    selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
    selectIcon:
      'flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em]',
  },

  'rating': {
    inner: '$reset',
    outer: '$reset mb-4',
    wrapper: '$reset'
  },

  'family:text': {},

  'family:date': {
    input:
      'w-full pl-9 pr-3 py-2 border-none text-black-700 dark:text-white placeholder-black-300',
  },

  // Specific styles apply only to a given input type
  color: {
    inner:
      'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
    input:
      '$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none',
    suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto',
  },

  radio: {
    inner:
      '$reset ring-1 ring-black-100 peer-checked:ring-primary focus-within:ring-2 focus-within:ring-primary dark:ring-black-500 dark:focus-within:ring-primary transition-shadow rounded-full',
    decorator:
      '$reset block size-5 flex text-transparent peer-checked:ring-primary peer-checked:text-primary',
    decoratorIcon: '$reset block size-3 m-auto',
  },

  checkbox: {
    decorator:
      'before:rounded-xs [&_svg]:transition-all [&_svg]:duration-500 [&_svg]:[stroke-dasharray:30px,30px] [&_svg]:[stroke-dasharray:30px,30px] [&_svg]:[stroke-dashoffset:30px] peer-checked:[&_svg]:delay-200 peer-checked:[&_svg]:[stroke-dashoffset:0]',
  },

  switch: {
    inner: '$reset'
  },

  range: {
    inner: '$reset flex items-center max-w-md',
    input: '$reset w-full mb-1 h-2 p-0 rounded-full',
    prefixIcon:
      '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon:
      '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
  },

  dropdown: {
    inner: innerDefault,
    input:
      'w-full px-3 py-3 border-none text-base text-black-700 dark:text-white placeholder-black-300',
    option:
      'px-3 py-3 hover:bg-primary-50 dark:text-white hover:text-primary transition-color cursor-pointer [&.selected]:!bg-primary-500 [&.selected]:text-white text-sm',
    decoration:
      'absolute right-3 top-1/2 -translate-y-1/2 transition-transform text-black-400 w-4 h-4',
  },

  file: {
    inner: '$reset',
    fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
    fileItemIcon: 'w-4 mr-2 shrink-0',
    fileList:
      'shrink grow peer px-3 py-2 formkit-multiple:data-[has-multiple="true"]:mb-6',
    fileName: 'break-all grow text-ellipsis',
    fileRemove:
      'relative z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-primary peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
    fileRemoveIcon: 'block text-base w-3 relative z-[2]',
    input:
      'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
    noFiles: 'flex w-full items-center px-3 py-2 text-gray-400',
    noFilesIcon: 'w-4 mr-2',
  },

  date: {
    inner: 'overflow-visible',
    input: 'w-full px-3 py-2 border-none placeholder-black-300',
  },

  phone: {
    inner: innerDefault,
    input:
      'w-full px-3 py-3 border-none text-base text-black-700 dark:text-white placeholder-black-300',
  },
}
