<script setup>
import { useCycleList } from '@vueuse/core'
import { computed, onBeforeUnmount } from 'vue'

import UseShape from '@/components/common/UseShape.vue'

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  loaderSize: {
    type: String,
    default: 'size-28',
  },
})

const { state: id, next, index } = useCycleList(['2', '16', '8'])

const colors = ['text-purple-600', 'text-orange-400', 'text-purple-300']

const currentColor = computed(() => colors[index.value % colors.length])

const loadingInterval = setInterval(() => next(), 1000)

const scaleRotate = {
  enterActiveClass: 'ease-in duration-300 transition-all',
  enterFromClass: 'opacity-0 scale-50 -rotate-90',
  enterToClass: 'opacity-100 rotate-0',
  leaveActiveClass: 'transition ease-out duration-500',
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0 scale-50 rotate-90',
}

onBeforeUnmount(() => {
  clearInterval(loadingInterval)
})
</script>

<template>
  <div class="relative" :class="loaderSize">
    <Transition v-bind="scaleRotate">
      <UseShape
        class="absolute size-full"
        :class="currentColor"
        :id="id"
        :key="id"
      />
    </Transition>
  </div>
</template>
